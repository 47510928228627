export const reload_sprinkle_st = (data) => {  //sprinkle újra beolvasása
    return {
      type: 'reload_sp',
      payload: data
    };
  };
  
  
  
  
  export const reload_st = (reload) => {  //lap újra beolvasása
    return {
      type: 'reload',
      payload: reload
    };
  };