import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 250,
    },
  },
}));

const id = (error) => {
 if (error === true){
   return "outlined-error"
 }
 else {
  return "outlined"
 }

};

const shrink = (arg) => {
  if (arg === "") {
    return false
  }

  else {
    return true
  }
}





export default function BasicTextFields(props) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField error = {props.error} id={id(props.error)} label={props.label} variant="outlined" onChange={props.change} style ={{width :props.width}} value ={props.value} InputLabelProps={{ shrink: shrink(props.value) }} defaultValue = {props.default} disabled = {props.disable} />
    </form>
  );
}