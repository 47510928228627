export const ThermostatdateTime_st = (data) => {
    return{
        type : 'ThermostatDateTime',
        payload : data
    }
}

export const ThermostatTemp_st = (data) => {
    return{
        type : 'ThermostatTemp',
        payload : data
    }
}

export const ThermostatTempSet_st = (data) => {
    return{
        type : 'ThermostatTempSet',
        payload : data
    }
}

export const ThermostatStateControl_st = (data) => {
    return{
        type : 'ThermostatStateControl',
        payload : data
    }
}