import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  

  const handleClose = () => {
    props.close();
  };

  const renderMessage = () => {
    if (props.message != null && props.message != "") {
     
      return (
        <DialogContent>
      <DialogContentText id="alert-dialog-description">
      {props.message}
    </DialogContentText>
    <DialogContentText id="alert-dialog-description">
      {props.message2}
    </DialogContentText>
    </DialogContent>
    )
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        
          {renderMessage()}
          
        <DialogActions>
          
          <Button onClick={handleClose} color="primary" autoFocus>
            Bezárás
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}