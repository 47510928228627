import React, { Component } from 'react';
import * as actions from '../components/actions';
import { connect } from 'react-redux';
import {BrowserView,MobileView} from "react-device-detect";
import {backend} from '../components/data/backend';
import {fetch_call_token_get} from "../components/common/fetch_token_get";
import ThermostatBrowser from './ThermostatBrowser';
import ThermostatMobile from './ThermostatMobile';
import {refreshToken} from '../components/common/refreshToken';
import {fetch_call_token_method} from "../components/common/fetch_token_method";
import {fetchLogout} from "../components/common/fetchLogout";





class Thermostat extends Component {

    constructor(props) {
        super(props);
    
        this.componentDidMount = () => {

            this.queryDB()
          
        }
    
      this.componentDidUpdate = () => {
          
      };
    
      this.state = {
          dateTime : "",
          temp : "",
          tempSet : "",
          stateControl : "",
          alert : false,
          alertTitle : "",
          alertMessage : "",
          progressBar : true,
          progressButton : false,
         
      }

      this.stateControlFunction = this.stateControlFunction.bind(this)
      this.tempSet_toCS = this.tempSet_toCS.bind(this)
      this.sendDB = this.sendDB.bind(this)
      this.alertClose = this.alertClose.bind(this)
      this.logout = this.logout.bind(this)
    
        
      }


onLoginFail = () => {
const error = "Újra be kell jelentkezni!!!"
this.props.error_st(error)
this.props.history.push('/')
}

sendDB = () => {
    this.setState({progressButton : true})
    const url = backend+"/thermostat"
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const data ={"temp_set": this.state.tempSet, "state_control": this.state.stateControl, "temp_night": 10 , "state_night" : "False"};
    const value = fetch_call_token_method("post", url, token, data)
    value.then(data => {
        if (data.error === "token_expired") {
          refreshToken(this.sendDB, this.onLoginFail)
        }
        else if (data.date) {
            this.setState({progressButton : false})
            this.setState({datetime : data.date})
            this.setState({temp : this.round(data.temp)})
            this.setState({tempSet : data.temp_set})
            this.setState({stateControl : this.pythonStrToBoolean(data.state_control)})
            this.setState({alert : true})
            this.setState({alertTitle : "Sikeres beállítás!"})
        }
        else {
            this.setState({progressButton : false})
            this.setState({alert : true})
            this.setState({alertTitle : "Sikertelen beállítás!"})
            this.setState({alertMessage : "Próbálja később! Jelentekezen újta be!"})
        }  
    })
    .catch(
        (error) => {
          this.setState({progressButton : false})
          this.setState({alert : true})
          this.setState({alertTitle : "Sikertelen beállítás!"})
          this.setState({alertMessage : "Próbálja később!"})
        }
      )

}


alertClose = () => {
    this.setState({alert : false})
    this.setState({alertTitle : ""})
    this.setState({alertMessage : ""})
}


logout = () => {
    fetchLogout()
    sessionStorage.removeItem("access_token")
    sessionStorage.removeItem("refresh_token")
    this.props.history.push('/')
}




queryDB = () => {  // a bejelentkezett felh. rpi IP cím
    const url = backend+"/thermostat"
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const value = fetch_call_token_get(url, token)
    value.then(data => {
      if (data.error === "token_expired") {
        refreshToken(this.queryDB, this.onLoginFail)
      }
      else if (data.date)  {
          this.setState({progressBar : false})
          this.setState({datetime : data.date})
          this.setState({temp : this.round(data.temp)})
          this.setState({tempSet : data.temp_set})
          this.setState({stateControl : this.pythonStrToBoolean(data.state_control)})
      }
      else {
        this.onLoginFail()
      }

    })
    .catch(
      (error) => {
        this.setState({alert : true})
        this.setState({alertTitle : "Adatbázis hiba!"})
        this.setState({alertMessage : "Próbálja később!"})
      }
    )
  }

  round = (number) => { 
    return Math.round( number * 10 ) / 10
   };

  stateControlFunction = (data) => {
    this.setState({stateControl : data})
   
  }

  pythonStrToBoolean = (data) => {
    if (data === "True") {
        return true
    }
    else if (data === "False") {
        return false

}}

tempSet_toCS = (data) => {
    this.setState({tempSet : data}) // csak az option object value része kell a REST API miatt!
    
}

disabled = (value) => {  // cirle slider diable vagy nem
    if (value === true) {
      
      return false
    }
    else {
      
      return true 
    }
  }



render = () => {

    return(
        <div>
          <BrowserView>
            <ThermostatBrowser
               datetime = {this.state.datetime}
               temp = {this.state.temp}
               tempSet = {this.state.tempSet}
               stateControl = {this.state.stateControl}
               stateControlFunc = {this.stateControlFunction}
               tempSetToCS = {this.tempSet_toCS}
               disabled = {this.disabled}
               sendDB = {this.sendDB}
               alert = {this.state.alert}
               alertClose = {this.alertClose}
               alertTitle = {this.state.alertTitle}
               alertMessage = {this.state.alertMessage}
               logout = {this.logout}
               progressBar = {this.state.progressBar}
               progressButton = {this.state.progressButton}
            />
          </BrowserView>

          <MobileView>
            <ThermostatMobile
              datetime = {this.state.datetime}
              temp = {this.state.temp}
              tempSet = {this.state.tempSet}
              stateControl = {this.state.stateControl}
              stateControlFunc = {this.stateControlFunction}
              tempSetToCS = {this.tempSet_toCS}
              disabled = {this.disabled}
              sendDB = {this.sendDB}
              alert = {this.state.alert}
              alertClose = {this.alertClose}
              alertTitle = {this.state.alertTitle}
              alertMessage = {this.state.alertMessage}
              logout = {this.logout}
              progressBar = {this.state.progressBar}
              progressButton = {this.state.progressButton}
            />
          </MobileView>
        </div>
    )
}
}


const mapStateToProps = state => {
    
    return {  };
  };
  
  export default connect(mapStateToProps, actions)(Thermostat);
