//Proxy
export * from './StokeholeActions';
export * from './AuthActions';
export * from './sprinkleActions'
export * from './reloadActions'
export * from './gateControlActions'
export * from './thermostatActions'



export const error_st = (error) => {   // hibák (loginerror)
  return {
    type: 'error',
    payload: error
  };
};

export const server_st = (server) => {  //server beállítása
  return {
    type: 'server',
    payload: server
  };
};


export const user_rpi_st = (id, name, ip) => {  //user rpi
  return {
    type: 'user_rpi',
    id : id,
    name : name,
    ip : ip
  };
};


export const progress_st = (progress) => {  //progress bar 
  return {
    type: 'progress',
    payload: progress
  };
};




export const datetime_st = (datetime) => {    // dátum
  return {
    type: 'datetime',
    payload: datetime
  };
};

export const temp_st = (temp) => {   //thermostat temp set
  return {
    type: 'temp',
    payload: temp
  };
};


export const temp_set_st = (temp_set) => {   //thermostat temp set
  return {
    type: 'temp_set',
    payload: temp_set
  };
};

export const state_control_st = (state_control) => {   //tehermostat control
  return {
    type: 'state_control',
    payload: state_control
  };
};

export const temp_night_st = (temp_night) => {   //thermostat éjszakai áram hőm
  return {
    type: 'temp_night',
    payload: temp_night
  };
};

export const state_night_st = (state_night) => {   //thermostat control
  return {
    type: 'state_night',
    payload: state_night
  };
};

export const smtp_server_st = (smtp_server) => {   //smtp server
  return {
    type: 'smtp_server',
    payload: smtp_server
  };
};

export const smtp_port_st = (smtp_port) => {   //smtp port
  return {
    type: 'smtp_port',
    payload: smtp_port
  };
};

export const smtp_user_st = (smtp_user) => {   //smtp user
  return {
    type: 'smtp_user',
    payload: smtp_user
  };
};

export const smtp_password_st = (smtp_password) => {   //smtp password
  return {
    type: 'smtp_password',
    payload: smtp_password
  };
};

export const smtp_address_1_st = (smtp_address_1) => {   //smtp address 1
  return {
    type: 'smtp_address_1',
    payload: smtp_address_1
  };
};

export const smtp_address_2_st = (smtp_address_2) => {   //smtp address 2
  return {
    type: 'smtp_address_2',
    payload: smtp_address_2
  };
};

export const temp_alarm_st = (temp_alarm) => {   //hőmérséklet riasztási szint
  return {
    type: 'temp_alarm',
    payload: temp_alarm
  };
};

export const menu_st = (menu) => {   //menu state
  return {
    type: 'menu',
    payload: menu
  };
};


export const server_gatest = (gatestate) => {   //kapu állapota
  return {
    type: 'gatest',
    payload: gatestate
  };
};


export const server_garagest = (garagestate) => {      // garázs állapota
  return {
    type: 'garagest',
    payload: garagestate
  };
};

export const redirect_st= (redirect) => {     //redirect page
  return {
    type: 'redirect',
    payload: redirect
  };
};

export const row_st= (row) => {     //redirect page
  return {
    type: 'row',
    payload: row
  };
};



export const password_1_st = (password_1) => {    //login password
  return {
    type: 'password_1',
    payload: password_1
  };
};



export const url_st = (url) => {   //REST API url -k
  return {
    type: 'url',
    payload: url
  };
};


export const delay_st = (delay) => {    //működési késleltetések
  return {
    type: 'delay',
    payload: delay
  };
};


export const message_gate_st = (message_gate) => {  // működési üzenetek
  return {
    type: 'message_gate',
    payload: message_gate
  };
};

export const message_garage_st = (message_garage) => {  // működési üzenetek
  return {
    type: 'message_garage',
    payload: message_garage
  };
};

export const disable_garage_st = (disable_garage) => {  //button disable set garage
  return {
    type: 'disable_garage',
    payload: disable_garage
  };
};

export const disable_gate_st = (disable_gate) => {  //button disable set gate
  return {
    type: 'disable_gate',
    payload: disable_gate
  };
};

export const message_use_garage_st = (message_use_garage) => {  //message use garage beállítása
  return {
    type: 'message_use_garage',
    payload: message_use_garage
  };
};

export const message_use_gate_st = (message_use_gate) => {  //message use gate beállítása
  return {
    type: 'message_use_gate',
    payload: message_use_gate
  };
};



export const needclose_st = (needclose) => {  //message use beállítása
  return {
    type: 'needclose',
    payload: needclose
  };
};




export const heartbeat_st = (heartbeat) => {  //server heartbeat beállítása
  return {
    type: 'heartbeat',
    payload: heartbeat
  };
};


export const hb_reload_st = (hb_reload) => {  //server heartbeat reload
  return {
    type: 'hb_reload',
    payload: hb_reload
  };
};

export const datepicker_st = (datepicker) => {  //server heartbeat reload
  return {
    type: 'datepicker',
    payload: datepicker
  };
};


