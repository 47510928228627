import React, { Component } from 'react';
import * as actions from '../components/actions';
import { connect } from 'react-redux';
import {BrowserView,MobileView} from "react-device-detect";
import {backend} from '../components/data/backend';
import {fetch_call_token_get} from "../components/common/fetch_token_get";
import StokeholeBrowser from './StokeholeBrowser';
import StokeholeMobile from './StokeholeMobile';
import {refreshToken} from '../components/common/refreshToken';
import {fetch_call_token_method} from "../components/common/fetch_token_method";
import {fetchLogout} from "../components/common/fetchLogout";






class Stokehole extends Component {

    constructor(props) {
        super(props);
    
        this.componentDidMount = () => {


            this.queryDB()
            this.props.rpiService_st(sessionStorage.getItem("rpiService")) // le menjen a headerbe a menű state
        
        }
    
      this.componentDidUpdate = () => {
          
      };
    
      this.state = {
          dateTime : "",
          tempPuffer : "",
          tempHotwater : "",
          tsetProduct : "",
          timer : "",
          stateControl : "",
          stateTimer : "",
          alert : false,
          alertTitle : "",
          alertMessage : "",
          progressBar : true,
          progressButton : false,
          remainTime : "",
          queryAlert : false
          
         
      }

      this.stateControlFunction = this.stateControlFunction.bind(this)
      this.stateTimerFunction = this.stateTimerFunction.bind(this)
      this.tempSet_toCS = this.tempSet_toCS.bind(this)
      this.sendDB = this.sendDB.bind(this)
      this.queryDB = this.queryDB.bind(this)
      this.alertClose = this.alertClose.bind(this)
      this.logout = this.logout.bind(this)
      this.setTimer = this.setTimer.bind(this)
      this.plus = this.plus.bind(this)
      this.minus = this.minus.bind(this)
      this.plusTimer = this.plusTimer.bind(this)
      this.minusTimer = this.minusTimer.bind(this)
    
        
      }


onLoginFail = () => {
const error = "Újra be kell jelentkezni!!!"
this.props.error_st(error)
this.props.history.push('/')
}

sendDB = () => {
    this.setState({progressButton : true})
    const url = backend+"/boiler"
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const data ={"tsetProduct": this.state.tsetProduct, "stateControl": this.state.stateControl, "timer": this.state.timer , "stateTimer" : this.state.stateTimer};
    const value = fetch_call_token_method("post", url, token, data)
    value.then(data => {
        if (data.error === "token_expired") {
          refreshToken(this.sendDB, this.onLoginFail)
        }
        else if (data.message == "OK") {
            console.log(data)
            this.setState({progressButton : false})
            this.setState({tsetProduct : data.tsetProduct})
            this.setState({timer : data.timer})
            this.setState({stateControl : this.pythonStrToBoolean(data.stateControl)})
            this.setState({stateTimer : this.pythonStrToBoolean(data.stateTimer)})
            this.setState({remainTime : data.remainTime})
            this.setState({alert : true})
            this.setState({alertTitle : "Sikeres beállítás !"})
            this.queryDB()
            
        }
        else {
            this.setState({progressButton : false})
            this.onLoginFail()
        }  
    })
    .catch(
        (error) => {
          this.setState({progressButton : false})
          this.setState({alert : true})
          this.setState({alertTitle : "Adatbázis hiba!"})
          this.setState({alertMessage : "Próbálja később!"})
        }
      )

}


alertClose = () => {
    this.setState({alert : false})
    this.setState({alertTitle : ""})
    this.setState({alertMessage : ""})
}


logout = () => {
    fetchLogout()
    sessionStorage.removeItem("access_token")
    sessionStorage.removeItem("refresh_token")
    this.props.history.push('/')
}




queryDB = () => {  // a bejelentkezett felh. rpi IP cím
    console.log('queryDB')
    const url = backend+"/stokehole"
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const value = fetch_call_token_get(url, token)
    value.then(data => {
      if (data.error === "token_expired") {
        refreshToken(this.queryDB, this.onLoginFail)
      }
      else if (data)  {
          console.log(data)
          const temp_1 = data.temp_ds18b20_1
          const temp_2 = data.temp_ds18b20_2
          this.setState({progressBar : false})
          this.setState({dateTime : data.date})
          this.setState({tempPuffer : this.round(temp_1)})
          this.setState({tempHotwater : this.round(temp_2)})
  
          if (this.state.queryAlert) {
            this.setState({alert : true})
            this.setState({alertTitle : "Sikeres frissítés !"})
          }
          
      }
      else {
        console.log("loginFail")
        this.onLoginFail()
      }

    })
    .catch(
      (error) => {
        this.setState({alert : true})
        this.setState({alertTitle : "Adatbázis hiba!"})
        this.setState({alertMessage : "Próbálja később!"})
        
      }
    )
  }

  refresh = () => {
    this.setState({queryAlert : true})
    this.queryDB()
  }

  round = (number) => { 
    return Math.round( number * 10 ) / 10
   };

  stateControlFunction = (data) => {
    this.setState({stateControl : data})
  }

  stateTimerFunction = (data) => {
    this.setState({stateTimer : data})
  }

  pythonStrToBoolean = (data) => {
    if (data === "True") {
        return true
    }
    else if (data === "False") {
        return false

}}

tempSet_toCS = (data) => {
    this.setState({tsetProduct : data}) // csak az option object value része kell a REST API miatt!
    
}

setTimer = (data) => {  // timer állítása
  this.setState({timer : data})
  console.log(this.state.timer)
}

disabled = (value) => {  // cirle slider diable vagy nem
    if (value === true) {
      
      return false
    }
    else {
      
      return true 
    }
  }

  plus = () => {
    
    this.setState(prevstate => ({tsetProduct : prevstate.tsetProduct + 1}))
  
  }

  minus = () => {
    this.setState(prevstate => ({tsetProduct : prevstate.tsetProduct - 1}))
  }

  plusTimer = () => {
    
    this.setState(prevstate => ({timer : prevstate.timer + 5}))
  
  }

  minusTimer = () => {
    this.setState(prevstate => ({timer : prevstate.timer - 5}))
  }



render = () => {

    return(
        <div>
          <BrowserView>
            <StokeholeBrowser
               datetime = {this.state.dateTime}
               tempPuffer = {this.state.tempPuffer}
               tempHotwater = {this.state.tempHotwater}
               tsetProduct = {this.state.tsetProduct}
               stateControl = {this.state.stateControl}
               stateTimer = {this.state.stateTimer}
               timer = {this.state.timer}
               stateControlFunc = {this.stateControlFunction}
               stateTimerFunc = {this.stateTimerFunction}
               tempSetToCS = {this.tempSet_toCS}
               setTimer = {this.setTimer}
               disabled = {this.disabled}
               sendDB = {this.sendDB}
               queryDB = {this.refresh.bind(this)}
               alert = {this.state.alert}
               alertClose = {this.alertClose}
               alertTitle = {this.state.alertTitle}
               alertMessage = {this.state.alertMessage}
               logout = {this.logout}
               progressBar = {this.state.progressBar}
               progressButton = {this.state.progressButton}
               remainTime = {this.state.remainTime}
               
            />
          </BrowserView>

          <MobileView>
            <StokeholeMobile
              datetime = {this.state.dateTime}
              tempPuffer = {this.state.tempPuffer}
              tempHotwater = {this.state.tempHotwater}
              tsetProduct = {this.state.tsetProduct}
              stateControl = {this.state.stateControl}
              stateTimer = {this.state.stateTimer}
              timer = {this.state.timer}
              stateControlFunc = {this.stateControlFunction}
              stateTimerFunc = {this.stateTimerFunction}
              tempSetToCS = {this.tempSet_toCS}
              setTimer = {this.setTimer}
              disabled = {this.disabled}
              sendDB = {this.sendDB}
              queryDB = {this.refresh.bind(this)}
              alert = {this.state.alert}
              alertClose = {this.alertClose}
              alertTitle = {this.state.alertTitle}
              alertMessage = {this.state.alertMessage}
              logout = {this.logout}
              progressBar = {this.state.progressBar}
              progressButton = {this.state.progressButton}
              remainTime = {this.state.remainTime}
              plus = {this.plus}
              minus = {this.minus}
              plusTimer = {this.plusTimer}
              minusTimer = {this.minusTimer}
            />
          </MobileView>
        </div>
    )
}
}


const mapStateToProps = state => {
    
    return {  };
  };
  
  export default connect(mapStateToProps, actions)(Stokehole);
