import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import "./ESPControl.css";
import { GiSmallFire } from "react-icons/gi"
import { IconContext } from "react-icons";
import Switch from "react-switch";
import Button from '@material-ui/core/Button';
import Progress from "../components/common/ProgressBar";
import MyCircleSlider from '../components/common/CircleSlider';
import AlertDialog from '../components/common/alertDialog';
import Divider from'@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
   
    

    '& > *': {
      margin: theme.spacing(2),
      width: theme.spacing(60),
      height: theme.spacing(25),
    },
  },
}));


const ESPControlMobile = (props) => {
  const classes = useStyles();
   

const renderGarageInfo = () => {
  if (props.garageGate === "open") {
    return(
      <h3 style={{backgroundColor: "blue",color: "white", marginLeft : "40%", marginRight : "40%"}}>Garázs nyitva</h3>
    )
  }

  else if (props.garageGate === "close") {
    return(
      <h3 style={{backgroundColor: "blue",color: "white", marginLeft : "30%", marginRight : "30%"}}>Garázs zárva</h3>
    )
  }

  else if (props.garageGate === "operateClose") {
    return(
      <h3 style={{backgroundColor: "red",color: "white", marginLeft : "40%", marginRight : "40%"}}>Garázs zárás!</h3>
    )
  }

  else if (props.garageGate === "operateOpen") {
    return(
      <h3 style={{backgroundColor: "red",color: "white", marginLeft : "40%", marginRight : "40%"}}>Garázs nyitás!</h3>
    )
  }

}

const renderGarageButton = () => {
  //console.log(props.garageGate)
  //console.log(props.progressGarageButton)
if (props.progressGarageButton){
    return(
    <div>
    <Progress/>
    </div>)
}
else {
    if (props.garageGate === "open") {
    return(
    <div>
    
    <Button disabled = {props.disbledGarage} variant="outlined" size="large" onClick={props.garageControl}
        
        > Garázs zárás </Button> 
  
    </div>)}
    else if (props.garageGate === "close") {
      return(
        <div>
        <Button disabled = {props.disbledGarage} variant="outlined" size="large" onClick={props.garageControl}
            
            > Garázs nyitás </Button> 
      
        </div>)

    }}}

    const renderFrontGateInfo = () => {
      console.log(props.frontGate)
      if (props.frontGate === "open") {
        return(
          <h3 style={{backgroundColor: "blue",color: "white", marginLeft : "40%", marginRight : "40%"}}>Kapu nyitva</h3>
        )
      }
    
      else if (props.frontGate === "close") {
        return(
          <h3 style={{backgroundColor: "blue",color: "white", marginLeft : "30%", marginRight : "30%"}}>Kapu zárva</h3>
        )
      }
    
      else if (props.frontGate === "operateClose") {
        return(
          <h3 style={{backgroundColor: "red",color: "white", marginLeft : "40%", marginRight : "40%"}}>Kapu zárás!</h3>
        )
      }
    
      else if (props.frontGate === "operateOpen") {
        return(
          <h3 style={{backgroundColor: "red",color: "white", marginLeft : "40%", marginRight : "40%"}}>Kapu nyitás!</h3>
        )
      }
    
    }

    const renderFrontGateButton = () => {
      console.log(props.frontGate)
      if (props.progressFrontGateButton){
          return(
          <div>
          <Progress/>
          </div>)
      }
      else {
          if (props.frontGate === "open") {
          return(
          <div>
          <Button variant="outlined" size="large" onClick={props.frontGateControl}
              
              > Kapu zárás </Button> 
        
          </div>)}
          else if (props.frontGate === "close"){
            return(
              <div>
              <Button variant="outlined" size="large" onClick={props.frontGateControl}
                  
                  > Kapu nyitás </Button> 
            
              </div>)
      
          }}}

const renderButton = () => {
  if (props.progressButton){
      return(
      <div style = {{marginBottom : 20}}>
      <Progress/>
      </div>)
  }
  else {
      return(
      <div style = {{marginBottom : 30, display : "flex", justifyContent : "center"}}>
        <div style = {{margin : 10}}>
      
      <Button variant="outlined" size="large" onClick={props.refresh}
          
          > Állapot FRISSÍTÉS </Button> 
          </div>
    
      </div>)
  
  }
  }
          

    
const renderPage = () => {
    if (props.progressBar) {
        return (
            <div className="App" style = {{marginTop : 40}}>
                <Progress/>
            </div>
        )
    }

    else 
        return (
            <div>




<div className="App">

<div style={{color:"blue"}}>
            <h3>A pontos idő: {props.datetime} </h3>
            <h3>ESP heartBeat : {props.ESPdatetime}</h3>
            <h1> </h1>
            <div className={classes.root}>
            <Paper elevation={3} >
            <div style={{display : "flex", flexDirection: "row", fontSize: 25, justifyContent: "center", fontWeight: "Bold", marginTop: 10, color : "blue"}}>
            <div> Garázs vezérlés</div>
            <div style={{backgroundColor: "blue",color: "white", marginLeft: 10}}>  </div>
          
            </div>

            <div style = {{marginTop : 20, marginBottom : 40}}> 
             {renderGarageInfo()}
             {renderGarageButton()}
             
             </div>  
             </Paper>

             


             <Paper elevation={3} >
             <div style={{display : "flex", flexDirection: "row", fontSize: 25, justifyContent: "center", fontWeight: "Bold", marginTop: 10, color : "blue"}}>
            <div> Kapu vezérlés</div>
            <div style={{backgroundColor: "blue",color: "white", marginLeft: 10}}>  </div>
          
            </div>

            <div style = {{marginTop : 30, marginBottom : 40}}> 
             {renderFrontGateInfo()}
             {renderFrontGateButton()}
             
             </div> 
             </Paper>
             </div> 

             {renderButton()}

        </div>

  

      <AlertDialog 
        open = {props.alert}
        title = {props.alertTitle}
        message = {props.alertMessage}
        close = {props.alertClose}
      />





        </div>
        </div>

        )
}
   

return (
<div>
<HeaderMobile
    logout = {props.logout}
/>

  {renderPage()}
  </div>

)
}



export default ESPControlMobile;
