import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  
  /*color : {
      color : "white"
  },*/

  iconroot : {
      color : "white"
     
  },

  size : {
      fontSize : 40
  }

}));

export default function SettingIconButtons() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton classes={{root: classes.iconroot}}  >
        <SettingsIcon classes={{fontSizeLarge : classes.size}}  fontSize="large" />
      </IconButton>
    </div>
  );
}