export const USER = 'user';
export const PASSWORD = 'password';
export const SU = 'su';

//sprinkle state
/*export const ID = 'id';
export const NAME = 'name';
export const START_TIME = 'start_time';
export const SPRINKLE_DURATION = 'sprinkle_duration';
export const INTERVAL = 'interval';*/

export const ZONE1 = "zone1"
export const ZONE2 = "zone2"
export const ZONE3 = "zone3"
export const SPRINKLE = "sprinkle"