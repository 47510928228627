import React, { Component } from 'react';
import './App_mobile.css';
import  { Link } from 'react-router-dom'
import { FiSettings, FiDatabase } from "react-icons/fi";
import { IconContext } from "react-icons";
import Button from '@material-ui/core/Button';


class AppMobile extends Component {



    render = () => {

        return (
            <div className="App">
        <div className="App-header">
          <div></div>
          <div className="App-header-text">
          PowerPi
          </div>
          <div className="App-header-icon">

        
          <IconContext.Provider value={{ color: "white", size : "25" }}>
          <div>
            <FiSettings />
          </div>
          </IconContext.Provider>
       
          </div>
        </div>

        
       
      <h2>Kérlek jelenkezz be!</h2>
      <div style={{marginTop: 20}}>  Felhasználó név:</div>
      <input
        style={{marginTop: 8}}
        type='text'
        onChange={this.props.myChangeHandler_un}
      />
      <div  style={{marginTop : 10}}>Jelszó:</div>
      <input
        type='password'
        onChange={this.props.myChangeHandler_pw}
      />
      <p></p>
      <Button variant="outlined" size="small" onClick={this.props.onButtonPress}
        
      > Bejelentkezés </Button> 

      <div style={{marginTop : 10, color: "red"}}  >

        <h4> {this.props.error} </h4>


      </div>
    
      </div>






        );



    }
};



export default AppMobile;