import React, { Component } from 'react';
import  { Link, Redirect } from 'react-router-dom'
import * as actions from '../components/actions';
//import {stokehole_st} from ''
import { connect } from 'react-redux';
//import { FiLogOut, FiMenu } from "react-icons/fi";
//import { GiSmallFire } from "react-icons/gi"
//import { IconContext } from "react-icons";
import "./Main.css";
//import Button from '@material-ui/core/Button';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import {BrowserView,MobileView} from "react-device-detect";
import MainMobile from "./MainMobile";
import MainBrowser from "./MainBrowser";
import Progress from "../components/common/ProgressBar";
import MainBrowserSU from "./MainBrowserSU"
//import SimpleAlerts from "../component/common/alert";
import {backend} from '../components/data/backend';
import {fetch_call_token_get} from '../components/common/fetch_token_get'





class Main extends Component {

  constructor(props) {
    super(props);

    this.componentDidMount = () => {
      console.log("SU"+this.props.su)
      this.current_user()
      this.userRpi()
      this.props.progress_st(false)
    }

  this.componentDidUpdate = () => {
      
  };

  this.state = {
      service : "",

  }

    
  }



  

  current_user = () => {
    this.props.error_st('')
    const token ="Bearer " + localStorage.getItem("access_token")
    const url = backend+"/current_user"
        fetch(url, {
            method: "get",
            mode: 'cors'
            , headers: {
              "Authorization" : token
            }
            })
            .then(
                (response) => {
                if (response.status !== 200) {
                
                this.onLoginFail()
                return; }
                return response.json() })
                .then(
                    (data) => {
                    const role = data.role
                    const userid = data.userid  
                    console.log("Current user: "+role+userid)
                    this.props.su_st(role)
                    this.props.history.push('/main')
                    })
                    .catch(
                        (error) => {
                          console.log("Fetch hiba")
                          console.log(error)
                        
                    })

  }

  /*
  userRpi = () => {  // a bejelentkezett felh. rpi IP cím
    const url = backend+"/user_rpi"
    const token ="Bearer " + localStorage.getItem("access_token")
    const value = fetch_call_token_get(url, token)
    value.then(data => {
      sessionStorage.setItem("rpiIP", data.ip)
      sessionStorage.setItem("rpiService", data.service)
      this.setService()
    })
    .catch(
      (error) => {
        console.log(error)
      }
    )
  }
  */

 setService = () => {
   const service = sessionStorage.getItem("rpiService")
   if (service.slice(0,1) === "T") {
     this.setState({service : "T"})
   }
   console.log(service)
 }

 

 renderServiceBrowser = () => {
   if (this.state.service === "") {
     return <Progress/>
   }
   else if (this.state.service === "T") {
   }
 }

  

  

  

  stokehole  = () => {
    this.props.progress_st(true)
    this.props.error_st('')
    const token ="Bearer " + localStorage.getItem("access_token")
    const url = "https://powerpi-systems.ddns.net/stokehole"
    fetch(url, {
      method: "get",
      mode: 'cors'
      , headers: {
        "Authorization" : token
      }
      })
      .then(
          (response) => {
          if (response.status !== 200) {
          
          this.onLoginFail()
          return; }
          return response.json() })
          .then(
              (data) => {
              const temp_1 = data.temp_ds18b20_1
              const temp_2= data.temp_ds18b20_2
              const date  = data.date
              this.props.stokehole_st(temp_1, temp_2, date)
              this.props.progress_st(false)
              })
              .catch(
                  (error) => {
                    console.log("Fetch hiba")
                    console.log(error)
                  
              })
}



  


    onLoginFail = () => {
      const error = "Újra be kell jelentkezni!!!"
      this.props.error_st(error)
      this.props.history.push('/')
    }


    
    // menu open
    handleClick = (event) => {
      this.props.menu_st(event.currentTarget);
      console.log("menu state: "+this.props.menu)
    };

    // manu close
    handleClose = () => {
      this.props.menu_st(null)
      console.log("menu state: "+this.props.menu)
    };

    renderStokehole = () => {
      if (this.props.progress){
        return(
        <div>
          <Progress/>
        </div>)
      }
      if (this.props.active) {
        return (
          <div>
              <h1 style={{color: "blue"}}>Pontos idő : {this.props.date} </h1>
              <h1 style={{color: "blue"}}>Puffer : {this.props.temp_1} °C</h1>
              <h1 style={{color: "blue"}}>Használati melegvíz : {this.props.temp_2} °C</h1>
            </div>
        )
      }
      else {
        return (<div>
          
        </div> )
      
      }


    };

    sprinkle = () => {
      this.props.history.push('/sprinkle')
    }

    gatecontrol = () => {
      this.props.history.push('/gatecontrol')
    }
     
  
    render() {

        if (this.props.su == 'su') {
          return(
            <div>
             <BrowserView>
                <MainBrowserSU
                handleclick = {this.handleClick.bind(this)}
                menu = {this.props.menu}
                handleclose_bind = {this.handleClose.bind(this)}
                handleclose = {this.handleClose} 
                >
                </MainBrowserSU>
             </BrowserView>
            </div>
          )

        }



        if (this.props.redirect) {
            return <Redirect to='/'/>;
          }

        return (
          <div>
          <BrowserView>
          <MainBrowser
            handleclick = {this.handleClick.bind(this)}
            menu = {this.props.menu}
            handleclose_bind = {this.handleClose.bind(this)}
            handleclose = {this.handleClose}
            name = {this.props.name}
            ip = {this.props.ip}
            temp_1 = {this.props.temp_1}
            temp_2 = {this.props.temp_2}
            date = {this.props.date}
            stokehole = {this.stokehole.bind(this)}
            render_stokehole = {this.renderStokehole()}
            onclick = {this.sprinkle.bind(this)}
            gatecontrol = {this.gatecontrol.bind(this)}
            >
          </MainBrowser>
          </BrowserView>
          <MobileView>
            <MainMobile
            >
            </MainMobile>
          </MobileView>
        
            </div>
           

        )
    };

}




const mapStateToProps = state => {
    const {id, name , ip } = state.user_rpi
    const {temp_1, temp_2, date, active} = state.stokehole
    const {su} = state.auth
    return {  redirect : state.redirect, user : state.user, password : state.password, server : state.server, error : state.error, datetime : state.datetime, temp : state.temp, temp_set : state.temp_set, state_control : state.state_control, temp_night : state.temp_night, state_night : state.state_night, menu : state.menu, id, name, ip, temp_1, temp_2, date, active, progress : state.progress, su};
  };
  
  export default connect(mapStateToProps, actions)(Main);
  