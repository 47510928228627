const INITIAL_STATE = {
    temp_1 : '',
    temp_2 : '',
    date : '',
    active : false
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'stokehole':
      return {...state, temp_1 : action.temp_1, temp_2 : action.temp_2, date : action.date, active : true}
    case 'stokehole_clear' :
        return {...state, active : false}
    default:
      return state;
  }
};
