const INITIAL_STATE = {

    dateTime : "",
    temp : "",
    tempSet : "",
    stateControl : ""
  
    
    }
    
    export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) { 
        case "ThermostatDateTime":
            return {...state, dateTime : action.payload}
        case "ThermostatTemp":
            return {...state, temp : action.payload}
        case "ThermostatTempSet":
            return {...state, tempSet : action.payload}
        case "ThermostatStateControl":
            return {...state, stateControl : action.payload}
    default:
        return state;
    }
        };