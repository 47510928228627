import React from 'react';
import  { Link} from 'react-router-dom';
import { IconContext } from "react-icons";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FiLogOut, FiMenu } from "react-icons/fi";
import { FaThermometerHalf, FaFlask, FaDumpsterFire } from "react-icons/fa";
//import CircularIndeterminate from '../component/common/ProgressBar'
import SprinkleIconButtons from "../components/common/SprinkleIcon"
//import FontAwesome from "./common/fa"
import { GiGate } from "react-icons/gi";

//import Switch from "react-switch";
import "./Main.css";
//import Button from '@material-ui/core/Button';
//import MyCircleSlider from './common/CircleSlider';




const MainBrowser = (props) => {

    
    

        return (
        
        
            <div className="App">
            <div className="App-header">
            <div className="App-header-icon">
              
              <IconContext.Provider value={{ color: "white", size : "30" }}>
              <div>
                <FiMenu onClick={props.handleclick} />
              </div>
              </IconContext.Provider>
              <Menu
                id="simple-menu"
                anchorEl={props.menu}
                keepMounted
                open={Boolean(props.menu)}
                onClose={props.handleclose}
          >
    
                <Link to="/" style={{ textDecoration: 'none' }}>
                <MenuItem onClick={props.handleclose_bind}>Beállítások</MenuItem>
                </Link>
            
              </Menu>
             
              </div>
              <div className="App-header-text">
              <h1>Welcome to PowerPi Systems</h1>
              </div>
              <div className="App-header-icon">
    
              <Link to="/" >
              <IconContext.Provider value={{ color: "white", size : "30" }}>
              <div>
                <FiLogOut />
              </div>
              </IconContext.Provider>
              </Link>
              </div>
            </div>
            </div>
           
           

        )
    };


export default MainBrowser;



