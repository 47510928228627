import React from "react";
import { CircleSlider } from "react-circle-slider";

const MyCircleSlider = ( {value, onChange ,circleColor, circleWidth, min, max, stepSize, showTooltip, progressColor, knobColor, disabled}) => {
    
    return (
        <CircleSlider 
            value={value} 
            onChange={onChange} 
            circleColor={circleColor}
            circleWidth={circleWidth}
            min={min}
            max={max}
            stepSize={stepSize}
            showTooltip={showTooltip}
            progressColor={progressColor}
            knobColor={knobColor}
            disabled={disabled}
            />
    );
};

export default MyCircleSlider;