import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import "./BoilerControl.css";
import { GiSmallFire } from "react-icons/gi"
import { IconContext } from "react-icons";
import Switch from "react-switch";
import Button from '@material-ui/core/Button';
import Progress from "../components/common/ProgressBar";
import MyCircleSlider from '../components/common/CircleSlider';
import AlertDialog from '../components/common/alertDialog';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  paper1: {
    display: 'flex',
    //flexWrap: 'wrap',
    justifyContent : "center",
    marginLeft : "2%",
    marginRight : "2%",
    //border : "2px solid",
   
    //backgroundColor : "blue",
    '& > *': {
      
      width: "90%",
      height: theme.spacing(11),
    },
  },

  paper2: {
    display: 'flex',
    //flexWrap: 'wrap',
    justifyContent : "center",
    marginLeft : "2%",
    marginRight : "2%",
    marginTop : 20,
    //border : "2px solid",
   
    //backgroundColor : "blue",
    '& > *': {
      
      width: "90%",
      height: "60%",
    },
  },

  paper3: {
    display: 'flex',
    //flexWrap: 'wrap',
    justifyContent : "center",
    marginLeft : "2%",
    marginRight : "2%",
    marginTop : 20,
    //border : "2px solid",
   
    //backgroundColor : "blue",
    '& > *': {
      
      width: "90%",
      height: "60%",
    },
  },

  blueIcon : {
    color : "blue"
  },

}));


const TimerSlider = withStyles({
  root: {
    color: 'blue',
    
  },
  track: {

    backgroundColor : "blue",
  },
  rail: {
    
    backgroundColor : "purple",
    width : 2
  },
})(Slider);

const BoilerSlider = withStyles({
  root: {
    color: 'blue',
    
  },

  thumb : {
    height: 20,
    width: 20,
    marginTop: -7
  },

  track: {
    height : 8,
    backgroundColor : "blue",
    borderRadius: 4
  },
  rail: {
    height : 8,
    backgroundColor : "#7D041C",
    borderRadius: 4
    
  },
})(Slider);





const BoilerControlMobile = (props) => {

  
  

  const classes = useStyles();

    const render_fire = () => {
        if (props.tempProduct < props.tsetProduct && props.stateControl === true) {
          return <IconContext.Provider value={{ color: "red", size : "30" }}>
            <div>
              <GiSmallFire />
            </div>
            </IconContext.Provider>
        } 
        else {
          return <IconContext.Provider value={{ color: "#d7d9fa", size : "30" }}>
            <div>
              <GiSmallFire />
            </div>
            </IconContext.Provider>
        }
  
      }

const renderButton = () => {
if (props.progressButton){
    return(
    <div>
    <Progress/>
    </div>)
}
else {
  return(
    <div style = {{marginBottom : 30, display : "flex", justifyContent : "center"}}>
      <div style = {{margin : 10}}>
    <Button variant="outlined" size="small" onClick={props.sendDB}
        
        > Beállítás</Button> 
        </div>
        <div style = {{margin : 10}}>
    <Button variant="outlined" size="small" onClick={props.queryDB}
        
        > FRISSÍTÉS </Button> 
        </div>
  
    </div>)

}


}

const sliderChange = (event, value) => {
  props.setTimer(value)
}

const boilerSliderChange = (event, value) => {
  props.tempSetToCS(value)
}

const displayRTData = (data) => {
  if (data === "noTimer") {
    return (<div>Több mint : {props.timer} perc. </div>)
  }
}

const renderRemainTime = () => {
  if (props.stateTimer) {
    return (
      <div style = {{marginLeft : 20}}>
        <div style={{color:"blue", fontSize : 18, fontWeight : "bold"}}> Hátralévő idő (perc)  :</div>
        <div style={{backgroundColor: "blue",color: "white", marginTop: 5}}> {displayRTData(props.remainTime)}</div>
      </div>
    )

  }
}



    
const renderPage = () => {
    if (props.progressBar) {
        return (
            <div className="App" style = {{marginTop : 40}}>
                <Progress/>
            </div>
        )
    }

    else 
        return (
            <div>




<div className="App">



<div style={{color:"blue"}}>

<h3>A pontos idő: {props.datetime} </h3>
<h1> </h1>
<div className = {classes.paper1}>
<Paper elevation={3} >
<div style={{display : "flex", flexDirection: "row", fontSize: 22, justifyContent: "center", fontWeight: "Bold", color : "blue", marginTop : "10px" }}>
<div> Termék hőmérséklet : </div>
<div style={{backgroundColor: "blue",color: "white", marginLeft: 10}}> {props.tempProduct} </div>
<div style={{marginLeft: 10}}>C°</div>
</div>

<div style={{display : "flex", flexDirection: "row", fontSize: 20, justifyContent: "center", fontWeight: "Bold", marginTop : 10, color : "blue"}}>
<div> Kazán hőmérséklet : </div>
<div style={{backgroundColor: "blue",color: "white", marginLeft: 10}}> {props.tempBoiler} </div>
<div style={{marginLeft: 10}}>C°</div>
</div>

</Paper>
</div>
</div>




<div className = {classes.paper2}>
<Paper elevation={3} >
        <div className="input_mobileSlider">
            <h3 style={{color:"blue"}}> Beállított hőmérséklet (C°)  :</h3>
            
            <div style={{marginTop:0}}>
            <Switch checked={props.stateControl} onChange={props.stateControlFunc} offColor={"#656262"} onColor={"#3549f9"} uncheckedIcon={false}/></div>
            
            <div style={{marginTop: 35, width : "70%"}}>
            
            <BoilerSlider
              value={props.tsetProduct}
              //getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-always"
              step={1}
              //marks
              min={20}
              max={98}
              valueLabelDisplay="on"
              onChange= {boilerSliderChange}
              disabled={props.disabled(props.stateControl)}
            /> 
            <div disabled style = {{display : "flex", width : "60%", justifyContent : "space-around", marginLeft : "20%"}}>
           
              <IconButton disabled={props.disabled(props.stateControl)} >

              <RemoveCircleIcon classes = {{colorPrimary : classes.blueIcon}} color = "primary" onClick = {props.minus}/>
              </IconButton>

              <IconButton disabled={props.disabled(props.stateControl)} >

              <AddCircleIcon classes = {{colorPrimary : classes.blueIcon}} color = "primary" onClick = {props.plus} />

              </IconButton>
              
            </div>
            
             </div>
            <div style = {{marginTop : 0, marginBottom : 5}}>
             {render_fire()}
          
          </div>
    
        </div>
        </Paper>
</div>

<div className = {classes.paper3}>
<Paper elevation={3} >
        <div className="input_mobileSlider">
            <h3 style={{color:"blue"}}> Hőntartás (perc)  :</h3>
            
            <div style={{marginTop:0}}>
            <Switch checked={props.stateTimer} onChange={props.stateTimerFunc} offColor={"#656262"} onColor={"#3549f9"} uncheckedIcon={false}/></div>
            
            <div style={{marginTop: 35, width : "90%"}}>
            <TimerSlider
              value={props.timer}
              //getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-always"
              step={10}
              marks
              min={10}
              max={300}
              valueLabelDisplay="on"
              onChange= {sliderChange}
              disabled={props.disabled(props.stateTimer)}
            /> 
        
             </div>

             <div disabled style = {{display : "flex", width : "60%", justifyContent : "space-around"}}>
           
           <IconButton disabled={props.disabled(props.stateTimer)} >

           <RemoveCircleIcon classes = {{colorPrimary : classes.blueIcon}} color = "primary" onClick = {props.minusTimer}/>
           </IconButton>

           <IconButton disabled={props.disabled(props.stateTimer)} >

           <AddCircleIcon classes = {{colorPrimary : classes.blueIcon}} color = "primary" onClick = {props.plusTimer} />

           </IconButton>
           
         </div>
            <div style = {{marginTop : 5, marginBottom : 5}}>
            
            {renderRemainTime()}
          </div>

         
    
        </div>
        </Paper>
</div>



<div></div>



<p></p>
     
    {renderButton()}

      <AlertDialog 
        open = {props.alert}
        title = {props.alertTitle}
        message = {props.alertMessage}
        close = {props.alertClose}
      />





        </div>
        </div>

        )
}
   

return (
<div>
<HeaderMobile
    logout = {props.logout}
/>

  {renderPage()}
  </div>

)
}



export default BoilerControlMobile;
