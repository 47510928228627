const INITIAL_STATE = {
  username : '',
  password : '',
  su : '',
  rpiIP : '',
  rpiService : '',

}


export default (state = INITIAL_STATE, action) => {
switch (action.type) {
  case 'user' :
    return {...state, username : action.payload};
  case 'password' :
    return {...state, password : action.payload}
  case 'su' :
    return {...state, su : action.payload}
  case 'rpiService':
    return {...state, rpiService : action.payload}
  default:
    return state;
}
};



