import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



export default function SimpleSelect2(props) {
  const classes = useStyles();
 
 return (
    <div> 
      <FormControl variant="outlined" className={classes.formControl} error={props.error}>
        <InputLabel id="demo-simple-select-outlined-label">{props.label} </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={props.change}
          value = {props.value}
          label = {props.label}
          disabled = {props.disable}
          
          
        >
          
          <MenuItem value={true}>Bekapcsolva</MenuItem>      
          <MenuItem value={false}>Kikapcsolva</MenuItem>
        </Select>
      </FormControl>
      
    </div>
  );
}