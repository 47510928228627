import {USER, PASSWORD, SU} from './types'

export const user_st= (username) => {     //user
    
    return {
      type: USER,
      payload : username,
    };
  };


export const password_st= (password) => {     //user
  return {
    type: PASSWORD,
    payload : password,
  };
};

export const su_st= (su) => {     //user
  return {
    type: SU,
    payload : su,
  };
};

export const rpiIP_st= (data) => {     //user
  return {
    type: "rpiIP",
    payload : data,
  };
};


export const rpiService_st= (data) => {     //user
  return {
    type: "rpiService",
    payload : data,
  };
};
 
 