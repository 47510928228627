import React, { Component } from 'react';
import  { Link, Redirect } from 'react-router-dom'
import * as actions from '../components/actions';
//import {stokehole_st} from ''
import { connect } from 'react-redux';
//import { FiLogOut, FiMenu } from "react-icons/fi";
//import { GiSmallFire } from "react-icons/gi"
//import { IconContext } from "react-icons";
import "./Sprinkle.css";
//import Button from '@material-ui/core/Button';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import {BrowserView,MobileView} from "react-device-detect";
import MainMobile from "../main/MainMobile";
import SprinkleBrowser from "./SprinkleBrowser";
import SprinkleMobile from "./SprinkleMobile";
import Progress from "../components/common/ProgressBar";
import MainBrowserSU from "../main/MainBrowserSU";
import SprinkleTable from "./SprinkleTable";
import {backend} from '../components/data/backend';
import {refreshToken} from '../components/common/refreshToken';
import {fetch_call_token_get} from '../components/common/fetch_token_get';
import AlertDialog from '../components/common/alertDialog';
import { fetch_call_token_method } from '../components/common/fetch_token_method';
//import PopupSprinkle from "./sprinkle_popup";


class Sprinkle extends Component {

  constructor(props) {
    super(props);

  this.componentDidMount = () => {
    console.log("SU"+this.props.su)
    this.user_check()
    this.user_rpi()
    this.sprinkle()
    this.props.progress_st(false)
  }

  this.componentDidUpdate = () => {
    if (this.props.reload) {
      this.props.reload_st(false);
      this.props.sprinkle_name_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].name)
      const date = this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].start_time;
      const new_date = date.slice(0,10)+"T"+date.slice(11,16) // backend datetime to picker datetime
      this.props.sprinkle_start_time_st(new_date);
      this.props.sprinkle_duration_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].sprinkle_duration);
      this.props.manual_start_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].manual_start)
      this.props.manual_start_time_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].manual_start_time);
      this.props.interval_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].interval);
      this.props.manual_prohibit_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].manual_prohibit);
      this.props.zone_operate_st(this.props.sprinkle[this.select_value(this.props.sprinkleSetup.id)].zone_operate);
      
    }
  }

  

  this.state = {
    alert : false,
    alertTitle : "",
    alertMessage : "",
    
  };
} 

select_value = (value) => {
  if (value == 1) {
      return 0
  }
  else if (value == 2) {
      return 1
  }
  else if (value == 3) {
    return 2
}
}



  user_check = () =>  {
    this.props.error_st('')
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const url = backend+"/user_check"
    const value = fetch_call_token_get(url, token)
    value.then(data => {
      if (data.error === "token_expired") {
        refreshToken(this.user_check, this.onLoginFail)
      }
      else if (data.message === "OK")  {
        this.props.rpiService_st(data.rpiService)
      }
      else {
        this.onLoginFail()
      }

    })
    .catch(
      (error) => {
        this.setState({alert : true})
        this.setState({alertTitle : "Adatbázis hiba!"})
        this.setState({alertMessage : "Próbálja később!"})
      }
    )
    

  }

  user_rpi = () => {
    this.props.error_st('')
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const url = backend+"/user_rpi"
        fetch(url, {
            method: "get",
            mode: 'cors'
            , headers: {
              "Authorization" : token
            }
            })
            .then(
                (response) => {
                if (response.status !== 200) {
                
                this.onLoginFail()
                return; }
                return response.json() })
                .then(
                    (data) => {
                    const name = data.name
                    const id = data.id  
                    const ip = data.ip
                    console.log("User rpi: "+name+"@"+ip+"@"+id)
                    this.props.user_rpi_st(id, name, ip)
                    console.log("User rpi states :"+ this.props.ip)
                    })
                    .catch(
                        (error) => {
                          console.log("Fetch hiba")
                          console.log(error)
                        
                    })
  }

  rpi_services = () => {
    this.props.error_st('')
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const url = backend+"/rpi_services"
        fetch(url, {
            method: "get",
            mode: 'cors'
            , headers: {
              "Authorization" : token
            }
            })
            .then(
                (response) => {
                if (response.status !== 200) {
                
                this.onLoginFail()
                return; }
                return response.json() })
                .then(
                    (data) => {
                    const stokehole = data.stokehole
                    const gate = data.gate 
                    const name = data.rpi_name
                    const id = data.rpi_id
                    const ip = data.rpi_ip
                    console.log("User rpi: "+name)
                    this.props.user_rpi_st(id, name, ip)
                    console.log("User rpi states :"+ this.props.ip)
                    })
                    .catch(
                        (error) => {
                          console.log("Fetch hiba")
                          console.log(error)
                        
                    })
  }

  

  sprinkle  = () => {
    this.props.progress_st(true)
    this.props.error_st('')
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const url = backend+"/sprinkle_status"
    fetch(url, {
      method: "get",
      mode: 'cors'
      , headers: {
        "Authorization" : token
      }
      })
      .then(
          (response) => {
          if (response.status !== 200) {
          
          this.onLoginFail()
          return; }
          return response.json() })
          .then(
              (data) => {
              console.log(data.time)
              this.props.datetime_st(data.time)
              this.props.sprinkle_st(data.Zona_adatok)
              console.log(this.props.sprinkle)
              console.log(this.props.datetime)
              })
              .catch(
                  (error) => {
                    console.log("Fetch hiba")
                    console.log(error)
                  
              })
}

logout = () => {
    this.props.error_st('')
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const url = backend+"/logout"
    fetch(url, {
      method: "get",
      mode: 'cors'
      , headers: {
        "Authorization" : token
      }
      })
      .then(
          (response) => {
          if (response.status !== 200) {
          
          this.onLoginFail()
          return; }
          return response.json() })
          .then(
              (data) => {
              console.log(data.message)
              this.props.progress_st(false)
              this.props.history.push('/')
              })
              .catch(
                  (error) => {
                    console.log("Fetch hiba")
                    console.log(error)
                  
              })
}

 

//boolean_to_str = ()

sprinkle_setup  = () => {
  //this.props.progress_st(true)
  this.props.error_st('')

  const n = this.props.sprinkleSetup.start_time
  const date = n.slice(0,10)+" "+n.slice(11,16) //datetime átalakítás to backend
  console.log("manual st " +this.props.sprinkleSetup.manual_start)

  const data ={"id": this.props.sprinkleSetup.id, "name" : this.props.sprinkleSetup.name, "start_time" : date, "sprinkle_duration" : this.props.sprinkleSetup.sprinkle_duration, "interval" : this.props.sprinkleSetup.interval, "manual_start" : this.props.sprinkleSetup.manual_start.toString(), "manual_start_time" : this.props.sprinkleSetup.manual_start_time, "manual_prohibit" : this.props.sprinkleSetup.manual_prohibit.toString()   };     
  const myData = JSON.stringify(data);

  const token ="Bearer " + sessionStorage.getItem("access_token")
  const url = backend+"/sprinkle_setup"
  fetch(url, {
    method: "post",
    mode: 'cors',
    body: myData,
     headers: {
      "Authorization" : token,
      "Content-type": "application/json"
    }
    })
    .then(
        (response) => {
        //if (response.status !== 200) {
        //this.onLoginFail()
        //return; }
        return response.json() })
        .then(
            (data) => {
            console.log(data.message)
            if (data.error === "token_expired") {
              refreshToken(this.sprinkle_setup, this.onLoginFail)
            }
            else if (data.message === "OK"){
              this.sprinkle()
              this.setState({alert : true})
              this.setState({alertTitle : "Sikeres beállítás!"})
            }
            else {
              this.setState({alert : true})
              this.setState({alertTitle : "Sikertelen beállítás!"})
              this.setState({alertMessage : "Próbálja később! Jelentekezen újra be!"})

            }
            })
            .catch(
                (error) => {
                  console.log("Fetch hiba")
                  this.setState({alert : true})
                  this.setState({alertTitle : "Sikertelen beállítás!"})
                  this.setState({alertMessage : "Próbálja később!"})
                
            })
}


alertClose = () => {
  this.setState({alert : false})
  this.setState({alertTitle : ""})
  this.setState({alertMessage : ""})
}

    sprinkle_table = () => {
        if (this.props.sprinkle === ""){
            
                return(
                <div>
                  <Progress/>
                </div>)
        }

        //console.log(this.props.sprinkle)
        else {
        return(
            <SprinkleTable
              sprinkle = {this.props.sprinkle} />
        )}
    }

    sprinkle_edit = () => {
      this.openModal()
    }



  


    onLoginFail = () => {
      const error = "Újra be kell jelentkezni!!!"
      this.props.error_st(error)
      this.props.history.push('/')
    }


    
    // menu open
    handleClick = (event) => {
      this.props.menu_st(event.currentTarget);
      console.log("menu state: "+this.props.menu)
    };

    // manu close
    handleClose = () => {
      this.props.menu_st(null)
      console.log("menu state: "+this.props.menu)
    };

    renderStokehole = () => {
      if (this.props.progress){
        return(
        <div>
          <Progress/>
        </div>)
      }
      if (this.props.active) {
        return (
          <div>
              <h1 style={{color: "blue"}}>Pontos idő : {this.props.date} </h1>
              <h1 style={{color: "blue"}}>Puffer : {this.props.temp_1} °C</h1>
              <h1 style={{color: "blue"}}>Használati melegvíz : {this.props.temp_2} °C</h1>
            </div>
        )
      }
      else {
        return (<div>
          
        </div> )
      
      }


    };



    
     
  
    render() {

        if (this.props.su == 'su') {
          return(
            <div>
             <BrowserView>
                <MainBrowserSU
                handleclick = {this.handleClick.bind(this)}
                menu = {this.props.menu}
                handleclose_bind = {this.handleClose.bind(this)}
                handleclose = {this.handleClose} 
                sprinkle_edit = {this.sprinkle_edit.bind(this)}
                
                
                >
                </MainBrowserSU>
             </BrowserView>
            </div>
          )

        }



        if (this.props.redirect) {
            return <Redirect to='/'/>;
          }

        return (
          <div>
          <BrowserView>

          <SprinkleBrowser
            handleclick = {this.handleClick.bind(this)}
            menu = {this.props.menu}
            handleclose_bind = {this.handleClose.bind(this)}
            handleclose = {this.handleClose}
            name = {this.props.name}
            ip = {this.props.ip}
            onclick = {this.sprinkle.bind(this)}
            sprinkle_table = {this.sprinkle_table()}
            sprinkle_state = {this.props.sprinkle}
            sprinkle_setup = {this.sprinkle_setup.bind(this)}
            sprinkle = {this.sprinkle.bind(this)}
            logout = {this.logout.bind(this)}
            datetime = {this.props.datetime}
            >
          </SprinkleBrowser>
          </BrowserView>
          <MobileView>
            <SprinkleMobile
              datetime = {this.props.datetime}
              logout = {this.logout.bind(this)}
              sprinkle_table = {this.sprinkle_table()}
              sprinkle = {this.props.sprinkle}
              sprinkle_setup = {this.sprinkle_setup.bind(this)}
            />
          </MobileView>

          <AlertDialog 
            open = {this.state.alert}
            title = {this.state.alertTitle}
            message = {this.state.alertMessage}
            close = {this.alertClose}
          />
        
            </div>
           

        )
    };

}




const mapStateToProps = state => {
    const {id, name , ip } = state.user_rpi
    const {temp_1, temp_2, date, active} = state.stokehole
    const {su} = state.auth
    return {  redirect : state.redirect, user : state.user, password : state.password, server : state.server, error : state.error, datetime : state.datetime, temp : state.temp, temp_set : state.temp_set, state_control : state.state_control, temp_night : state.temp_night, state_night : state.state_night, menu : state.menu, id, name, ip, temp_1, temp_2, date, active, progress : state.progress, su, zone1 : state.zone1, zone2 : state.zone2, zone3 : state.zone3, sprinkle : state.sprinkle, reload : state.reload, sprinkleSetup : state.sprinkleSetup};
  };
  
  export default connect(mapStateToProps, actions)(Sprinkle);
  