import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
    root: {
      position: 'relative',
    },
    
    circle: {
      color: 'blue',
      
    },
  });




export default function Progress() {

    const classes = useStyles();
  

  return (
    <div className={classes.root}>
      <CircularProgress
        className = {classes.circle} 
        size = {60}
        thickness = {8}
      />
    </div>
  );
}