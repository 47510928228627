import { combineReducers } from 'redux';

import ServerDateReducer from './ServerDateReducer';
import ServerGateReducer from './ServerGateReducer';
import ServerGarageReducer from './ServerGarageReducer';
import AuthReducer from './AuthReducer';
import UserRpiReducer from './UserRpiReducer';
import PasswordReducer from './PasswordReducer';
import Password_1Reducer from './Password_1Reducer';
import ErrorReducer from './ErrorReducer';
import StokeholeReducer from './StokeholeReducer';
import ProgressReducer from './ProgressReducer';
import UrlReducer from './UrlReducer';
import DelayReducer from './DelayReducer';
import MessageGateReducer from './MessageGateReducer';
import MessageGarageReducer from './MessageGarageReducer';
import MessageUseGarageReducer from './MessageUseGarageReducer';
import MessageUseGateReducer from './MessageUseGateReducer';
import DisableGarageReducer from './DisableGarageReducer';
import DisableGateReducer from './DisableGateReducer';
import ReloadReducer from './ReloadReducer';
import NeedCloseReducer from './NeedCloseReducer';
import ServerReducer from './ServerReducer'
import HeartbeatReducer from './HeartbeatReducer'
import HbReloadReducer from './HbReloadReducer'
import DatePickerReducer from './DatePickerReducer'
import RedirectReducer from './RedirectReducer'
import TempSetReducer from './TempSetReducer'
import StateControlReducer from './StateControlReducer'
import TempNightReducer from './TempNightReducer'
import StateNightReducer from './StateNightReducer'
import TempReducer from './TempReducer'
import MenuReducer from './MenuReducer'
import RowReducer from './RowReducer' ;
import SmtpServerReducer from './SmtpServerReducer' ;
import SmtpPortReducer from './SmtpPortReducer' ;
import SmtpUserReducer from './SmtpUserReducer' ;
import SmtpPasswordReducer from './SmtpPasswordReducer' ;
import SmtpAddress1Reducer from './SmtpAddress1Reducer' ;
import SmtpAddress2Reducer from './SmtpAddress2Reducer' ;
import TempAlarmReducer from './TempAlarmReducer' ;
import zone1Reducer from './zone1Reducer';
import zone2Reducer from './zone2Reducer';
import zone3Reducer from './zone3Reducer';
import sprinkleReducer from './sprinkleReducer';
import sprinkleSetupReducer from './sprinkleSetupReducer';
import gateControlReducer from './gateControlReducer';
import thermostatReducer from './ThermostatReducer';

export default combineReducers({
  redirect : RedirectReducer,
  datetime : ServerDateReducer,
  gatestate : ServerGateReducer,
  garagestate : ServerGarageReducer,
  auth : AuthReducer,
  user_rpi : UserRpiReducer,
  password : PasswordReducer,
  password_1 : Password_1Reducer,
  error : ErrorReducer,
  stokehole : StokeholeReducer,
  progress : ProgressReducer,
  url : UrlReducer,
  delay : DelayReducer,
  message_gate : MessageGateReducer,
  message_garage : MessageGarageReducer,
  message_use_garage : MessageUseGarageReducer,
  message_use_gate : MessageUseGateReducer,
  disable_garage : DisableGarageReducer,
  disable_gate : DisableGateReducer,
  reload : ReloadReducer,
  needclose : NeedCloseReducer,
  server : ServerReducer,
  heartbeat : HeartbeatReducer,
  hb_reload : HbReloadReducer,
  datepicker : DatePickerReducer,
  temp_set : TempSetReducer,
  state_control : StateControlReducer,
  temp_night : TempNightReducer,
  state_night : StateNightReducer,
  temp : TempReducer,
  menu : MenuReducer,
  row : RowReducer,
  smtp_server : SmtpServerReducer,
  smtp_port : SmtpPortReducer,
  smtp_user : SmtpUserReducer,
  smtp_password : SmtpPasswordReducer,
  smtp_address_1 : SmtpAddress1Reducer,
  smtp_address_2 : SmtpAddress2Reducer,
  temp_alarm : TempAlarmReducer,
  zone1 : zone1Reducer,
  zone2 : zone2Reducer,
  zone3 : zone3Reducer,
  sprinkle : sprinkleReducer,
  sprinkleSetup : sprinkleSetupReducer,
  gatecontrol : gateControlReducer,
  thermostat : thermostatReducer,

});
