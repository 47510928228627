import React, { Component } from 'react';
import * as actions from '../components/actions';
import { connect } from 'react-redux';
import {BrowserView,MobileView} from "react-device-detect";
import {backend} from '../components/data/backend';
import {fetch_call_token_get} from "../components/common/fetch_token_get";
import ESPControlBrowser from './ESPControlBrowser';
import ESPControlMobile from './ESPControlMobile';
import {refreshToken} from '../components/common/refreshToken';
import {fetch_call_token_method} from "../components/common/fetch_token_method";
import {fetchLogout} from "../components/common/fetchLogout";





class ESPControl extends Component {

    constructor(props) {
        super(props);
    
        this.componentDidMount = () => {
            this.user_check()
            this.queryDB()
          
        }
    
      this.componentDidUpdate = () => {
          
      };
    
      this.state = {
          ESPdatetime : "",
          datetime : "",
          frontGate : "",
          frontButton : "",
          garageGate : "", 
          garageButton : "",
          alert : false,
          alertTitle : "",
          alertMessage : "",
          progressBar : true,
          progressGarageButton : false,
          progressFrontGateButton : false,
          disabledGarage : false,
          queryAlert : false
         
      }

      this.stateControlFunction = this.stateControlFunction.bind(this)
      this.tempSet_toCS = this.tempSet_toCS.bind(this)
      //this.sendDB = this.sendDB.bind(this)
      this.alertClose = this.alertClose.bind(this)
      this.logout = this.logout.bind(this)
      this.garageControl = this.garageControl.bind(this)
      this.frontGateControl = this.frontGateControl.bind(this)
      this.testOperate = this.testOperate.bind(this)
    
        
      }

user_check = () =>  {
  this.props.error_st('')
  const token ="Bearer " + sessionStorage.getItem("access_token")
  const url = backend+"/user_check"
  const value = fetch_call_token_get(url, token)
  value.then(data => {
    if (data.error === "token_expired") {
      refreshToken(this.user_check, this.onLoginFail)
    }
    else if (data.message === "OK")  {
      this.props.rpiService_st(data.rpiService)
    }
    else {
      this.onLoginFail()
    }

  })
  .catch(
    (error) => {
      this.setState({alert : true})
      this.setState({alertTitle : "Adatbázis hiba!"})
      this.setState({alertMessage : "Próbálja később!"})
    }
  )
  

}


onLoginFail = () => {
const error = "Újra be kell jelentkezni!!!"
this.props.error_st(error)
this.props.history.push('/')
}

garageTimer = () => {
  this.setState({alert : true})
  this.setState({alertTitle : "Sikeres garázskapu vezérlés!"})
  this.setState({progressGarageButton : false})
  this.queryDB()

}

garagePrevState = () => {
  if (this.state.garageGate === "open") {
    this.setState({garageGate : "operateClose"})
  }

  else if (this.state.garageGate === "close") {
    this.setState({garageGate : "operateOpen"})
  }

}

testOperate = () => {
  this.setState({progressGarageButton : true})
  this.garagePrevState()
  this.setState({disapled : true})
  setTimeout(this.garageTimer, 5000) 

}


garageControl = () => {
    this.setState({progressGarageButton : true})
    if (this.state.garageGate === "open") {
      var url = backend+"/garazs_close"
    }
    else if (this.state.garageGate === "close"){
      var url = backend+"/garazs"
    }
    this.garagePrevState()
    console.log(url)
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const value = fetch_call_token_get(url, token)
    value.then(data => {
        console.log(data)
        if (data.error === "token_expired") {
          refreshToken(this.garagageControl, this.onLoginFail)
        }
        else if (data.message === "OK") {
            setTimeout(this.garageTimer, 22000) 
            
        }
        else if (data.message === "NOK")  {
            this.setState({progressGarageButton : false})
            this.setState({alert : true})
            this.setState({alertTitle : "Sikertelen beállítás!"})
            this.setState({alertMessage : "Próbálja később! Jelentekezen újta be!"})
        }  
    })
    .catch(
        (error) => {
          console.log(error)
          this.setState({progressGarageButton : false})
          this.setState({alert : true})
          this.setState({alertTitle : "Sikertelen beállítás!"})
          this.setState({alertMessage : "Próbálja később!"})
        }
      )

}

frontGateTimer = () => {
  this.setState({alert : true})
  this.setState({alertTitle : "Sikeres kapu vezérlés!"})
  this.setState({progressFrontGateButton : false})
  this.queryDB()

}

frontGatePrevState = () => {
  if (this.state.frontGate === "open") {
    this.setState({frontGate : "operateClose"})
  }

  else if (this.state.frontGate === "close") {
    this.setState({frontGate : "operateOpen"})
  }

}

frontGateControl = () => {
  this.setState({progressFrontGateButton : true})
  console.log(this.state.frontGate)
  if (this.state.frontGate === "open") {
    var url = backend+"/kapu_close"
  }
  else if (this.state.frontGate === "close"){
    var url = backend+"/open_full"
  }
  this.frontGatePrevState()
  console.log(url)
  const token ="Bearer " + sessionStorage.getItem("access_token")
  const value = fetch_call_token_get(url, token)
  value.then(data => {
      console.log(data)
      if (data.error === "token_expired") {
        refreshToken(this.frontGateControl, this.onLoginFail)
      }
      else if (data.message === "OK") {
          setTimeout(this.frontGateTimer, 22000) 
          
      }
      else if (data.message === "NOK")  {
          this.setState({progressfrontGateButton : false})
          this.setState({alert : true})
          this.setState({alertTitle : "Sikertelen beállítás!"})
          this.setState({alertMessage : "Próbálja később! Jelentekezen újta be!"})
      }  
  })
  .catch(
      (error) => {
        console.log(error)
        this.setState({progressfrontGateButton : false})
        this.setState({alert : true})
        this.setState({alertTitle : "Sikertelen beállítás!"})
        this.setState({alertMessage : "Próbálja később!"})
      }
    )

}


alertClose = () => {
    this.setState({alert : false})
    this.setState({alertTitle : ""})
    this.setState({alertMessage : ""})
}


logout = () => {
    fetchLogout()
    sessionStorage.removeItem("access_token")
    sessionStorage.removeItem("refresh_token")
    this.props.history.push('/')
}

garageState = (data) => {
  if (data) {
    this.setState({garageGate : "open"})
  }
  else {
    this.setState({garageGate : "close"})
  }
}

frontGateState = (data) => {
  if (data) {
    this.setState({frontGate : "close"})
  }
  else {
    this.setState({frontGate : "open"})
  }
}





queryDB = () => {  // a bejelentkezett felh. rpi IP cím
    const url = backend+"/readEsp1/1"
    const token ="Bearer " + sessionStorage.getItem("access_token")
    const value = fetch_call_token_get(url, token)
    value.then(data => {
      console.log(data)
      if (data.error === "token_expired") {
        refreshToken(this.queryDB, this.onLoginFail)
      }
      else if (data.message === "OK")  {
          console.log(data.esp)
          this.setState({progressBar : false})
          this.setState({ESPdatetime : data.esp.heartBeat})
          this.setState({datetime : data.datetime})
          //this.setState({frontGate : data.gatestate})
          //this.setState({garageGate : data.garagestate})
          this.garageState(data.garagestate)
          this.frontGateState(data.gatestate)
          if (this.state.queryAlert) {
            this.setState({alert : true})
            this.setState({alertTitle : "Sikeres frissítés !"})
          }
      }
      else {
        this.onLoginFail()
      }

    })
    .catch(
      (error) => {
        console.log(error)
        this.setState({alert : true})
        this.setState({alertTitle : "Adatbázis hiba!"})
        this.setState({alertMessage : "Próbálja később!"})
      }
    )
  }

  refresh = () => {
    this.setState({queryAlert : true})
    this.queryDB()
  }

  round = (number) => { 
    return Math.round( number * 10 ) / 10
   };

  stateControlFunction = (data) => {
    this.setState({stateControl : data})
   
  }

  pythonStrToBoolean = (data) => {
    if (data === "True") {
        return true
    }
    else if (data === "False") {
        return false

}}

tempSet_toCS = (data) => {
    this.setState({tempSet : data}) // csak az option object value része kell a REST API miatt!
    
}

disabled = (value) => {  // cirle slider diable vagy nem
    if (value === true) {
      
      return false
    }
    else {
      
      return true 
    }
  }



render = () => {

    return(
        <div>
          <BrowserView>
            <ESPControlBrowser
               datetime = {this.state.datetime}
               ESPdatetime = {this.state.ESPdatetime}
               garageGate = {this.state.garageGate}
               frontGate = {this.state.frontGate}
               disabledGarage = {this.state.disabled}
               garageControl = {this.garageControl}
               frontGateControl = {this.frontGateControl}
               progressGarageButton = {this.state.progressGarageButton}
               progressFrontGateButton = {this.state.progressFrontGateButton}
               alert = {this.state.alert}
               alertClose = {this.alertClose}
               alertTitle = {this.state.alertTitle}
               alertMessage = {this.state.alertMessage}
               logout = {this.logout}
               progressBar = {this.state.progressBar}
               refresh = {this.refresh.bind(this)}
               
            />
          </BrowserView>

          <MobileView>
            <ESPControlMobile
              datetime = {this.state.datetime}
              ESPdatetime = {this.state.ESPdatetime}
              garageGate = {this.state.garageGate}
              frontGate = {this.state.frontGate}
              disabledGarage = {this.state.disabled}
              garageControl = {this.garageControl}
              frontGateControl = {this.frontGateControl}
              progressGarageButton = {this.state.progressGarageButton}
              progressFrontGateButton = {this.state.progressFrontGateButton}
              alert = {this.state.alert}
              alertClose = {this.alertClose}
              alertTitle = {this.state.alertTitle}
              alertMessage = {this.state.alertMessage}
              logout = {this.logout}
              progressBar = {this.state.progressBar}
              refresh = {this.refresh.bind(this)}
            />
          </MobileView>
        </div>
    )
}
}


const mapStateToProps = state => {
    
    return {  };
  };
  
  export default connect(mapStateToProps, actions)(ESPControl);
