import React, { Component } from 'react';
import './App.css';
import * as actions from './components/actions';
import { connect } from 'react-redux';
import {BrowserView,MobileView} from "react-device-detect";
import AppBrowser from "./AppBrowser";
import AppMobile from "./AppMobile";
import Progress from "./components/common/ProgressBar";
import Button from '@material-ui/core/Button';
import {backend} from './components/data/backend';
import AlertDialog from './components/common/alertDialog';



class App extends Component {

  constructor(props) {
    super(props);

  this.componenDidMount = () => {
    
    
    this.props.redirect_st(false)
    this.props.progress_st(false)
    this.serverdata()
  }

  this.state = {
    alert : false,
    alertTitle : "",
    alertMessage : "",
    
  };
} 



  onButtonPress = async () => {
        this.props.progress_st(true)
        this.props.error_st('')
        const data ={"username": this.props.username, "password": this.props.password};
       
        const myData = JSON.stringify(data);

        const url = backend+"/login"
     
        fetch(url, {
            method: "post",
            mode: 'cors'
            , headers: {
                "Content-type": "application/json"
            }
            , body: myData
            })
            .then(
                (response) => {
                  /*
                if (response.status !== 200) {
                
                this.onLoginFail()
                return; }*/
                return response.json() })
                .then(
                    (data) => {
                    if (data.message === "OK") {
                    
                    sessionStorage.setItem("access_token", data.access_token)
                    sessionStorage.setItem("refresh_token", data.refresh_token)
                    sessionStorage.setItem("rpiIP", data.rpiIP)
                    sessionStorage.setItem("rpiService", data.rpiService)
                    this.props.rpiService_st(data.rpiService)
                    console.warn(data)
                    if (this.props.user == "su") {
                      
                    }
                    this.nextPage(data.rpiService)
                    this.props.progress_st(false)
                    console.log("Access token:"+data.access_token)
                    console.log(this.props.auth.rpiService)
                  }
                  else if (data.message === "NOK") {
                    this.onLoginFail()   
                  }
                    /*this.props.redirect_st(true)
                    console.log("redirect: "+this.props.redirect)*/
                    })
                    .catch(
                        (error) => {
                          this.props.progress_st(false)
                          console.log(`Fetch hiba: ${error}`)
                          this.setState({alert : true})
                          this.setState({alertTitle : "Szerver elérési hiba!"})
                          this.setState({alertMessage : "Próbálja később!"})   
                    })
    
    }  

  nextPage  = (data) => {
    const service = data
    console.warn(service)
    if (service.slice(0,1) === "T") {
      this.props.history.push('thermostat')
    }
    else if (service.slice(0,1) === "S") {
      this.props.history.push('sprinkle')
    }
    else if (service.slice(0,1) === "G") {
      this.props.history.push('gatecontrol')
    }
  }

  onLoginFail() {                    //ha nem jó az aauth
    const error = 'Hibás Felhasználó név vagy jelszó!'
    this.props.error_st(error)
    this.props.progress_st(false)
    
    
                }
  
  myChangeHandler_un = (event) => {
    this.props.user_st(event.target.value);
    console.log("User:"+this.props.username)
    
    
  }

  myChangeHandler_pw = (event) => {
    this.props.password_st(event.target.value);

    
    
  }

  myChangeHandler_server = (event) => {
    this.props.server_st(event.target.value);
    
  }

  render_button = () => {
    if (this.props.progress){
      return(
      <div>
        <Progress/>
      </div>)
    }
    else {
      return(
      <div>
        <Button variant="outlined" size="small" onClick={this.onButtonPress.bind(this)}
        
        > Bejelentkezés </Button> 
      </div>)

    }


  }

  alertClose = () => {
    this.setState({alert : false})
    this.setState({alertTitle : ""})
    this.setState({alertMessage : ""})
  }


  render() {

    
    /*console.log("render redirect :"+ this.props.redirect)
     if (this.props.redirect === true) {
      console.log("render redirect :"+ this.props.redirect)
       return <Redirect to='/main'/>;
     }*/

    return (
      <div>
        <BrowserView>
          <AppBrowser
          myChangeHandler_un = {this.myChangeHandler_un}
          myChangeHandler_pw = {this.myChangeHandler_pw}
          error = {this.props.error}
          button = {this.render_button()}>

          </AppBrowser>
        </BrowserView>
        <MobileView>
        <AppMobile
          myChangeHandler_un = {this.myChangeHandler_un}
          myChangeHandler_pw = {this.myChangeHandler_pw}
          onButtonPress = {this.onButtonPress.bind(this)}
          error = {this.props.error}>
          </AppMobile>
        </MobileView>

        <AlertDialog 
            open = {this.state.alert}
            title = {this.state.alertTitle}
            message = {this.state.alertMessage}
            close = {this.alertClose}
          />
      </div>
      
      
    );
  }
}

const mapStateToProps = state => {
  const {username, password, su} = state.auth
  return {error : state.error, server : state.server, redirect : state.redirect, username, password, su, progress: state.progress, auth : state.auth};
};

export default connect(mapStateToProps, actions)(App);