import {backend} from "../data/backend";


export const refreshToken = (value, loginfail) => {  // token frissítés
    const token ="Bearer " + sessionStorage.getItem("refresh_token")
    console.log(token)
    const url = backend+"/refresh"  
    fetch(url, {
        method: "post",
        mode: 'cors'
        , headers: {
          "Content-type": "application/json",
          "Authorization" : token
        }
        })
        .then(
            (response) => {
              console.log(response.status)
                if (response.status !== 200) {  // ha a refresh sem ok, akkor újra be kell jelentkezni
                    loginfail()
                    return; }
            return response.json() })
            .then(  // ha ok - new no fresh access token tárolása
                (data) => {
                    console.log(data)
                    sessionStorage.setItem("access_token", data.access_token)
                    value()  // func arg meghívása pl : is_admin()
                    
              })
                .catch(
                    (error) => {
                      console.log("Fetch hiba")
                      console.log(error)
                })

  }

