import {backend} from "../data/backend";

export const fetchLogout = () => {


  const token ="Bearer " + sessionStorage.getItem("access_token")
  const url = backend+"/logout" 

        return fetch(url, {
            method: 'get',
            mode: 'cors', 
            headers: {
              "Content-type": "application/json",
              "Authorization" : token
            },
           
            })
            .then(
              (response) => {
              return response.json() })      
}


