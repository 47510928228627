import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import "./Thermostat.css";
import { GiSmallFire } from "react-icons/gi"
import { IconContext } from "react-icons";
import Switch from "react-switch";
import Button from '@material-ui/core/Button';
import Progress from "../components/common/ProgressBar";
import MyCircleSlider from '../components/common/CircleSlider';
import AlertDialog from '../components/common/alertDialog';





const ThermostatMobile = (props) => {

    const render_fire = () => {
        if (props.temp < props.tempSet && props.stateControl === true) {
          return <IconContext.Provider value={{ color: "red", size : "30" }}>
            <div>
              <GiSmallFire />
            </div>
            </IconContext.Provider>
        } 
        else {
          return <IconContext.Provider value={{ color: "#d7d9fa", size : "30" }}>
            <div>
              <GiSmallFire />
            </div>
            </IconContext.Provider>
        }
  
      }

const renderButton = () => {
if (props.progressButton){
    return(
    <div>
    <Progress/>
    </div>)
}
else {
    return(
    <div>
    <Button variant="outlined" size="small" onClick={props.sendDB}
        
        > Beállítás / FRISSÍTÉS </Button> 
  
    </div>)

}


}

    
const renderPage = () => {
    if (props.progressBar) {
        return (
            <div className="App" style = {{marginTop : 40}}>
                <Progress/>
            </div>
        )
    }

    else 
        return (
            <div>




<div className="App">



        <div style={{color:"blue"}}>
            <h3>A pontos idő: {props.datetime} </h3>
            <h1> </h1>
            <div style={{display : "flex", flexDirection: "row", fontSize: 20, justifyContent: "center", fontWeight: "Bold"}}>
            <div> Mért hőmérséklet : </div>
            <div style={{backgroundColor: "blue",color: "white", marginLeft: 10}}> {props.temp} </div>
            <div style={{marginLeft: 10}}>C°</div>
            </div>
        </div>

        <div className="input_mobile">
            <h3 style={{color:"blue"}}> Beállított hőmérséklet (C°)  :</h3>
            
            <div style={{marginTop:10}}>
            <Switch checked={props.stateControl} onChange={props.stateControlFunc} offColor={"#656262"} onColor={"#3549f9"} uncheckedIcon={false}/></div>
            
            <div style={{marginTop: 15}}>
         <MyCircleSlider
            onChange={props.tempSetToCS}
            value={props.tempSet}
            circleColor={"blue"}
            circleWidth={15}
            min={5}
            max={25}
            stepSize={1}
            showTooltip={true}
            progressColor={"red"} 
            knobColor={"#78e4fa"} 
            disabled={props.disabled(props.stateControl)}
            /> 
             </div>
            <div style = {{marginTop : 15}}>
             {render_fire()}
          
          </div>
    
        </div>



<div></div>

<p></p>
     
    {renderButton()}

      <AlertDialog 
        open = {props.alert}
        title = {props.alertTitle}
        message = {props.alertMessage}
        close = {props.alertClose}
      />





        </div>
        </div>

        )
}
   

return (
<div>
<HeaderMobile
    logout = {props.logout}
/>

  {renderPage()}
  </div>

)
}



export default ThermostatMobile;
