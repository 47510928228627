import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import  {loadCSS}  from "./loadcss" // a loadcss.js file fel van másolva
import { green } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  
  /*color : {
      color : "white"
  },*/

  iconroot : {
      color : "blue"
     
  },

  size : {
      fontSize : 50
  }

}));

export default function SprinkleIconButtons(props) {
  const classes = useStyles();

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <div className={classes.root}>
      <IconButton classes={{root: classes.iconroot}} onClick={props.onclick}> 
        <Icon className="fas fa-cloud-rain" classes={{fontSizeLarge : classes.size}}  fontSize="large" />
      </IconButton>
    </div>
  );
}

