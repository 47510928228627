import React from 'react';
import './App.css';
import  { Link } from 'react-router-dom'
import { FiSettings, FiDatabase } from "react-icons/fi";
import { IconContext } from "react-icons";
import SettingsIconButton from "./components/common/SettingIcon"


const AppBrowser = (props) => 

    {


        return (
            <div className="App">
        <div className="App-header">
          <div></div>
          <div className="App-header-text">
          <h1>Welcome to PowerPi Systems</h1>
          </div>
          <div className="App-header-icon">
         
            <SettingsIconButton/>
         
          
          </div>
        </div>

      


      
       
      <h1>Kérlek jelenkezz be!</h1>
      <div style={{marginTop: 20}}>  Felhasználó név:</div>
      <input
        style={{marginTop: 8}}
        type='text'
        onChange={props.myChangeHandler_un}
      />
      <div  style={{marginTop : 10}}>Jelszó:</div>
      <input
        type='password'
        onChange={props.myChangeHandler_pw}
      />
      <p></p>
      
      {props.button}

      <div style={{marginTop : 10, color: "red"}}  >

        <h4> {props.error} </h4>


      </div>
    
      </div>






        );


};



export default AppBrowser;
