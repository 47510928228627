import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    maxWidth: 850,
    border : "solid 2px grey",

    
  },
  root : {
    fontWeight : "1000",
    fontSize : 12
  },

  root_body : {
    fontSize : 8
  },

  tableContainer : {
    display : "flex",
    maxWidth: 850,
    marginLeft : 10,
    justifyContent : "center",
    border : "solid 2px green",

  }
});

const boolean_to_str = (value) => {
    if (value) {
        return "Bekacsolva"
    }
    else {
        return "Kikapcsolva"
    }
}

const render_tbody = (value) => {
    var sprinkle = value;
    console.log ("Sprinkle :"+sprinkle)
    return sprinkle.map((row) => {
        const { id, name, start_time, sprinkle_duration, manual_start, manual_start_time, interval, manual_prohibit, zone_operate  } = row 
        
        return (
            
              <TableRow key={id}>
              <TableCell style={{fontSize : 10}} align="center">{id}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{name}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{start_time}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{sprinkle_duration}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{interval}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{boolean_to_str(manual_start)}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{manual_start_time}</TableCell>
              
              <TableCell style={{fontSize : 10}} align="center">{boolean_to_str(manual_prohibit)}</TableCell>
              <TableCell style={{fontSize : 10}} align="center">{boolean_to_str(zone_operate)}</TableCell>
            </TableRow>
          
        )

    })}



export default function SprinkleTable(props) {
  const classes = useStyles();

  return (
    <TableContainer classes = {{root : classes.tableContainer}} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell classes = {{root : classes.root}} align="center">Zone ID</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Zóna név</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Indítási idő</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Öntözési idő</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Öntözési periódus</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Kézi indítás</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Kézi önzözési idő</TableCell>
            
            <TableCell classes = {{root : classes.root}} align="center">Zóna tiltás</TableCell>
            <TableCell classes = {{root : classes.root}} align="center">Zóna működik</TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes = {{root: classes.root_body}}>
          
          {render_tbody(props.sprinkle)}
        
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}