import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import "./Sprinkle.css";
import { GiSmallFire } from "react-icons/gi"
import { IconContext } from "react-icons";
import Switch from "react-switch";
import Button from '@material-ui/core/Button';
import Progress from "../components/common/ProgressBar";
import MyCircleSlider from '../components/common/CircleSlider';
import AlertDialog from '../components/common/alertDialog';
import SprinkleList from './sprinkleList';
import PopupSprinkle from './sprinkle_popup';





const SprinkleMobile = (props) => {

   



    
const renderPage = () => {
    if (props.progressBar) {
        return (
            <div className="App" style = {{marginTop : 40}}>
                <Progress/>
            </div>
        )
    }

    else 
        return (
            <div>




<div className="App">



        <div style={{color:"blue"}}>
            <h3>A pontos idő: {props.datetime} </h3>
            <h1> </h1>
            <div style={{display : "flex", flexDirection: "row", fontSize: 20, justifyContent: "center", fontWeight: "Bold"}}>
            <div> Önzöző vezérlés </div>
            </div>
        </div>

        

        <SprinkleList
         sprinkleData = {props.sprinkle}
        />

<div>
                <PopupSprinkle
                  sprinkle_setup = {props.sprinkle_setup}
                />
              </div>



        
     


<div></div>

<p></p>
     
  

      <AlertDialog 
        open = {props.alert}
        title = {props.alertTitle}
        message = {props.alertMessage}
        close = {props.alertClose}
      />





        </div>
        </div>

        )
}
   

return (
<div>
<HeaderMobile
    logout = {props.logout}
/>

  {renderPage()}
  </div>

)
}



export default SprinkleMobile;
