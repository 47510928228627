import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import reducers from './components/reducers';


import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './main/Main';
//import Sprinkle from './components/Sprinkle'
//import GateControl from './components/GateControl';
import Thermostat from './thermostat/Thermostat';
import Sprinkle from './sprinkle/Sprinkle';
import Gatecontrol from './gatecontrol/Gatecontrol';
import BoilerControl from './boilercontrol/BoilerControl';
import ESPControl from './esp/ESPControl';
import Stokehole from './stokehole/Stokehole';

ReactDOM.render(

<Provider store={createStore(reducers)}>
<Router>
 <div>
    <Switch>
    <Route exact path="/" component={App}/>
    <Route path="/main" component={Main}/>
    <Route path="/sprinkle" component={Sprinkle}/>
    <Route path="/thermostat" component={Thermostat}/>
    <Route path="/gatecontrol" component={Gatecontrol}/>
    <Route path="/boiler" component={BoilerControl}/>
    <Route path="/esp" component={ESPControl}/>
    <Route path="/stokehole" component={Stokehole}/>
    </Switch>
  </div>
</Router>
  </Provider>,

  document.getElementById('root')
);


