import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SimpleSelect from '../components/common/selectInput';
import SimpleSelect2 from '../components/common/selectInput_2';
import { connect } from 'react-redux';
import * as actions from '../components/actions';
import MenuItem from '@material-ui/core/MenuItem';
import Progress from "../components/common/ProgressBar";
import BasicTextFields from "../components/common/textInput"
import DateAndTimePickers from "../components/common/datetime"


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const PopupSprinkle = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 /* const select_value = (value) => {
    var data = props.sprinkle
      if (data == ""){
            
        return(
        <div>
          <Progress/>
        </div>)
}
else{


      return data.map((row) => {
        const { id, name, start_time, sprinkle_duration, manual_start, manual_start_time, interval, manual_prohibit, zone_operate  } = row 
            if(id == value) {
                return {name, start_time, sprinkle_duration, manual_start, manual_start_time, interval, manual_prohibit, zone_operate}
        }
      }
      )}
  };*/
  const changeSelectInput = (event) => {
        props.sprinkle_id_st(event.target.value)
        props.reload_st(true)
  }

  const load_to_state = () => {
    if (props.sprinkle == "" || props.sprinkleSetup.id == "") {
      return
  }
  else {
        props.sprinkle_name_st(props.sprinkle[select_value(props.sprinkleSetup.id)].name)
        }}


  const changeName = (event) => {
    props.sprinkle_name_st(event.target.value)
  }

  const changeStartTime = (event) => {
    props.sprinkle_start_time_st(event.target.value)
  }

  const changeDuration = (event) => {
    props.sprinkle_duration_st(event.target.value)
  }

  const changeManualStart = (event) => {
    props.manual_start_st(event.target.value)
    console.log(props.sprinkleSetup.manual_start)
  }

  const changeManualStartTime = (event) => {
    props.manual_start_time_st(event.target.value)
    
  }

  const changeInterval = (event) => {
    props.interval_st(event.target.value)
    
  }

  const changeManualProhibit = (event) => {
    props.manual_prohibit_st(event.target.value)
    
  }



  /*const popup_data = () => {
      if (props.sprinkle == "" || props.sprinkleSetup.id == "") {
          return ""
      }
      else 
    {
        //return props.sprinkle[select_value(props.sprinkleSetup.id)]}
  }*/

  const select_value = (value) => {
      if (value == 1) {
          return 0
      }
      else if (value == 2) {
          return 1
      }
      else if (value == 3) {
        return 2
    }
  }

  const id_input = () => {  // dinamikusan állítja be a választási lehetőséget
      var data = props.sprinkle
      if (data == ""){
            
        return(
        <div>
          <Progress/>
        </div>)
}
else {
      return data.map((row) => {
         const { id } = row
      return (
        <MenuItem value={id}>{id}</MenuItem>
      )})
      }
  }

  

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClick}>
        Szerkesztés
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <SimpleSelect
           label = "Zóna ID" menu = {id_input()} change = {changeSelectInput} value = {props.sprinkleSetup.id}
           /> 
        <BasicTextFields label = "Zóna név" change = {changeName} value = {props.sprinkleSetup.name} 
        />
        <div style = {{marginTop : 10}}>
        <DateAndTimePickers label = "Indítási idő" change = {changeStartTime} value = {props.sprinkleSetup.start_time} />
        </div> 
        <div style = {{marginTop : 10}}>
        <BasicTextFields label = "Öntözési idő (perc)" change = {changeDuration} value = {props.sprinkleSetup.sprinkle_duration} 
        />
        </div> 
        <div style = {{marginTop : 0}}>
        <BasicTextFields label = "Öntözési periódus (óra)" change = {changeInterval}  value = {props.sprinkleSetup.interval} 
        />
        </div>
        <div style = {{marginTop : 0}}>
        <SimpleSelect2 label = "Kézi indítás" change = {changeManualStart} value = {props.sprinkleSetup.manual_start}
        />
        </div> 
        <div style = {{marginTop : 0}}>
        <BasicTextFields label = "Kézi öntözési idő (perc)" change = {changeManualStartTime} value = {props.sprinkleSetup.manual_start_time} 
        />
        </div>
        <div style = {{marginTop : 0}}>
        <SimpleSelect2 label = "Zóna tiltva" change = {changeManualProhibit} value = {props.sprinkleSetup.manual_prohibit}
        />
        </div>

        <div style = {{marginTop : 0}}>
        <SimpleSelect2 label = "Zóna állapot" value = {props.sprinkleSetup.zone_operate} disable = {true}
        />
        </div>
        <div style= {{display : "flex", marginTop : 8, justifyContent : "center", marginBottom: 10}} >
        <Button variant="outlined" size="small" onClick = {props.sprinkle_setup}>
        Mentés
      </Button>
      </div>
        
      </Popover>
    </div>
  );
}

const mapStateToProps = state => {
    return {sprinkle : state.sprinkle, sprinkleSetup : state.sprinkleSetup, reload : state.reload
}
}

export default connect(mapStateToProps, actions)(PopupSprinkle);