import React from 'react';
import  { Link, Redirect } from 'react-router-dom'
import * as actions from '../actions';
//import {stokehole_st} from ''
import { connect } from 'react-redux';
import { FiLogOut, FiMenu } from "react-icons/fi";
//import { GiSmallFire } from "react-icons/gi"
import { IconContext } from "react-icons";
import "./common.css";
//import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {BrowserView,MobileView} from "react-device-detect";
import FaIconButtons from "./FaIcon";
import Progress from "./ProgressBar";




const HeaderMobile = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
   
   const renderSetting = () => {
     console.log("rpis: "+props.auth.rpiService)
     if (props.auth.rpiService === "S") {
       return (
        <Link to="/sprinkle" style={{ textDecoration: 'none' }}>
        <MenuItem onClick={props.handleClose}>Öntözés vezérlés</MenuItem>
        </Link>
       )
     }
     else if (props.auth.rpiService === "SG") {
      return (
        <div>
       <Link to="/sprinkle" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Öntözés vezérlés</MenuItem>
       </Link>
       <Link to="/gatecontrol" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kapu vezérlés</MenuItem>
       </Link>
       </div>
      )
    }
    else if (props.auth.rpiService === "GS") {
      return (
        <div>
       <Link to="/gatecontrol" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kapu vezérlés</MenuItem>
       </Link>
       <Link to="/sprinkle" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Öntözés vezérlés</MenuItem>
       </Link>
       </div>
      )
    }

    else if (props.auth.rpiService === "GSB") {
      return (
        <div>
       <Link to="/gatecontrol" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kapu vezérlés</MenuItem>
       </Link>
       <Link to="/sprinkle" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Öntözés vezérlés</MenuItem>
       </Link>
       <Link to="/boiler" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Főző vezérlés</MenuItem>
       </Link>
       <Link to="/stokehole" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kazánház</MenuItem>
       </Link>
       <Link to="/esp" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>ESP lekérdezés</MenuItem>
       </Link>
       </div>
      )
    }

    else if (props.auth.rpiService === "BGS") {
      return (
        <div>
       <Link to="/boiler" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Főző vezérlés</MenuItem>
       </Link>
       <Link to="/gatecontrol" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kapu vezérlés</MenuItem>
       </Link>
       <Link to="/sprinkle" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Öntözés vezérlés</MenuItem>
       </Link>
       <Link to="/stokehole" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>Kazánház</MenuItem>
       </Link>
       <Link to="/esp" style={{ textDecoration: 'none' }}>
       <MenuItem onClick={props.handleClose}>ESP lekérdezés</MenuItem>
       </Link>
       
       </div>
      )
    }

   }

    return(
        <div className="App">
            <div className="App-header-mobile">
            <div className="App-header-icon">
              
              <IconContext.Provider value={{ color: "white", size : "25" }}>
              <div>
                <FiMenu onClick={openMenu} />
              </div>
              </IconContext.Provider>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
          >

                {renderSetting()}       
              </Menu>
              </div>
              <div className="App-header-text-mobile">
              <h1>PowerPi</h1>
              </div>

             
              <div className="App-header-icon">
              <IconContext.Provider value={{ color: "white", size : "25" }}>
              <div>
                <FiLogOut onClick={props.logout} />
              </div>
              </IconContext.Provider>
              </div>
              </div>
            </div>

    )
}

const mapStateToProps = state => {
  return {auth : state.auth
}
}

export default connect(mapStateToProps, actions)(HeaderMobile);