import React from 'react';
import  { Link} from 'react-router-dom';
import { IconContext } from "react-icons";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FiLogOut, FiMenu } from "react-icons/fi";
import Button from '@material-ui/core/Button';
import PopupSprinkle from './sprinkle_popup';
import FaIconButtons from "../components/common/FaIcon";
import Header from "../components/common/Header";

//import Switch from "react-switch";
import "./Sprinkle.css";
//import Button from '@material-ui/core/Button';
//import MyCircleSlider from './common/CircleSlider';




const SprinkleBrowser = (props) => {

    
    

        return (
        
        
            <div className="App">
            <Header
              logout = {props.logout}
            />



            <h2 style={{color: "blue"}}>
              Pontos idő : {props.datetime}
            </h2>

            <h2 style={{color: "blue"}}>
              Öntöző vezérlés
            </h2>


            <div className='table' style={{marginTop : 40}}>
            {props.sprinkle_table}
            </div>

              <div className = 'two_buttons'> 
                    <div>
                <PopupSprinkle
                  sprinkle_setup = {props.sprinkle_setup}
                />
              </div>

              <div>
                <Button variant="outlined" size="small" onClick = {props.sprinkle}
                
                > Frissítés </Button> 
              </div>


            </div>

    
           
    
                </div>
           
           

        )
    };


export default SprinkleBrowser;



