const INITIAL_STATE = {
    id : "",
    name : "",
    start_time : "",
    sprinkle_duration : "",
    interval : "",
    manual_start : "",
    manual_start_time : "",
    manual_prohibit : "",
    zone_operate : ""
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) { 
    case 'zone2':
        return {...state, id : action.id, name : action.name, start_time : action.start_time, sprinkle_duration : action.sprinkle_duration, interval : action.interval, manual_start : action.manual_start, manual_start_time : action.manual_start_time, manual_prohibit : action.manual_prohibit, zone_operate : action.zone_operate}  
    default:
    return state;
}
  };
  