export const stokehole_st = (temp_1, temp_2, date) => {  //stokehole state
    return {
      type: 'stokehole',
      temp_1 : temp_1,
      temp_2 : temp_2,
      date : date,

      
    };
  };
  

export const stokehole_clear = () => {  //ne mutassa a stokeholet
return {
    type: 'stokehole_clear',
};
};