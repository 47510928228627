import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import * as actions from '../components/actions';
import { connect } from 'react-redux';
import Progress from "../components/common/ProgressBar";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

const SprinkleList = (props) => {
  const classes = useStyles();

  const boolean_to_str = (value) => {
    if (value) {
        return "Bekacsolva"
    }
    else {
        return "Kikapcsolva"
    }
}
  
  const renderList = () => {
    var sprinkle = props.sprinkle

    if (sprinkle == ""){
            
        return(
        <div>
          <Progress/>
        </div>)
}

    else {
    console.log ("Sprinkle :"+sprinkle)
    return sprinkle.map((row) => {
        const { id, name, start_time, sprinkle_duration, manual_start, manual_start_time, interval, manual_prohibit, zone_operate  } = row 
        var title = "Zóna ID: "+id+" - Zóna név: "+name
        return (
            <div>
            <ListItem alignItems="flex-start">
            <ListItemText
              primary={title}
              secondary={
                  
                <React.Fragment>
                    <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Indítási idő :
                  </Typography>
                  {start_time}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Öntözési idő (perc):
                  </Typography>
                  {sprinkle_duration}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Öntözési periodus (óra):
                  </Typography>
                  {interval}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Kézi indítás:
                  </Typography>
                  {boolean_to_str(manual_start)}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Kézi öntőzési idő (perc):
                  </Typography>
                  {manual_start_time}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Zóna tiltás:
                  </Typography>
                  {boolean_to_str(manual_prohibit)}
                  </div>
                  <div>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    style = {{marginRight : 5}}
                  >
                    Zóna működik:
                  </Typography>
                  {boolean_to_str(zone_operate)}
                  </div>
                  </React.Fragment>
                  
                
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          </div>

        )

    })
}

      
}

  return (
    <List className={classes.root}>

    

    {renderList()}

     
    </List>
  );
}

const mapStateToProps = state => {
    return {sprinkle : state.sprinkle
}
}

export default connect(mapStateToProps, actions)(SprinkleList);