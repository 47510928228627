const INITIAL_STATE = {
    garage : "",
    gate : "",
    garageMessage : "",
    gateMessage : ""
    
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) { 
    case 'gatecontrol':
        return {...state, garage : action.garage, gate : action.gate}
    case 'garagemessage':
        return {...state, garageMessage : action.payload}
    case 'gatemessage':
        return {...state, gatemessage : action.payload}
    
    default:
    return state;
}
  };
  
  