import React from 'react';
import  { Link} from 'react-router-dom'
import { FiLogOut } from "react-icons/fi";
import { IconContext } from "react-icons";
import "./Main.css";





const MainMobile = (props) => {

    
    

        return (
        
        
            <div className="App">
        <div className="App-header">
        <div >
         
          </div>
          <div className="App-header-text">
          PowerPi
          </div>
          <div className="App-header-icon">

          <Link to="/" >
          <IconContext.Provider value={{ color: "white", size : "25" }}>
          <div>
            <FiLogOut />
          </div>
          </IconContext.Provider>
          </Link>
          </div>
        </div>

            </div>
        )
    };


export default MainMobile;



