import { ZONE1, ZONE2, ZONE3 } from "./types";

export const zone1_st = (id, name, start_time, sprinkle_duration, interval, manual_start, manual_start_time, manual_prohibit, zone_operate) => {  //zone 1 
    return {
      type: 'zone1',
      id : id,
      name : name,
      start_time : start_time,
      sprinkle_duration : sprinkle_duration,
      interval : interval,
      manual_start : manual_start,
      manual_start_time : manual_start_time,
      manual_prohibit : manual_prohibit,
      zone_operate : zone_operate
    };
  };

  export const zone2_st = (id, name, start_time, sprinkle_duration, interval, manual_start, manual_start_time, manual_prohibit, zone_operate) => {  //zone 2 
    return {
      type: 'zone2',
      id : id,
      name : name,
      start_time : start_time,
      sprinkle_duration : sprinkle_duration,
      interval : interval,
      manual_start : manual_start,
      manual_start_time : manual_start_time,
      manual_prohibit : manual_prohibit,
      zone_operate : zone_operate
    };
  };

  export const zone3_st = (id, name, start_time, sprinkle_duration, interval, manual_start, manual_start_time, manual_prohibit, zone_operate) => {  //zone 3 
    return {
      type: 'zone3',
      id : id,
      name : name,
      start_time : start_time,
      sprinkle_duration : sprinkle_duration,
      interval : interval,
      manual_start : manual_start,
      manual_start_time : manual_start_time,
      manual_prohibit : manual_prohibit,
      zone_operate : zone_operate
    };
  };

  export const sprinkle_st = (data) => {
      return{
          type : 'sprinkle',
          payload : data
      }
  }

  export const sprinkle_id_st = (data) => {
    return{
        type : 'sprinkle_id',
        payload : data
    }
}

export const sprinkle_name_st = (data) => {
  return{
      type : 'sprinkle_name',
      payload : data
  }
}

export const sprinkle_start_time_st = (data) => {
  return{
      type : 'sprinkle_start_time',
      payload : data
  }
}

export const sprinkle_duration_st = (data) => {
  return{
      type : 'sprinkle_duration',
      payload : data
  }
}

export const manual_start_st = (data) => {
  return{
      type : 'manual_start',
      payload : data
  }
}

export const manual_start_time_st = (data) => {
  return{
      type : 'manual_start_time',
      payload : data
  }
}

export const interval_st = (data) => {
  return{
      type : 'interval',
      payload : data
  }
}

export const manual_prohibit_st = (data) => {
  return{
      type : 'manual_prohibit',
      payload : data
  }
}

export const zone_operate_st = (data) => {
  return{
      type : 'zone_operate',
      payload : data
  }
}



  export const sprinkle_setup_st = (id, name, start_time, sprinkle_duration, interval, manual_start, manual_start_time, manual_prohibit, zone_operate) => {  //beállításhoz gyűjti az adatokat
    return {
      type: 'sprinkle_setup',
      id : id,
      name : name,
      start_time : start_time,
      sprinkle_duration : sprinkle_duration,
      interval : interval,
      manual_start : manual_start,
      manual_start_time : manual_start_time,
      manual_prohibit : manual_prohibit,
      zone_operate : zone_operate
    };
  };