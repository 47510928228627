export const fetch_call_token_method = (_method, url, token, data) => {

    const myData = JSON.stringify(data);

    return fetch(url, {
        method: _method,
        mode: 'cors'
        , headers: {
          "Content-type": "application/json",
          "Authorization" : token
        },
        body: myData
        })
        .then(
          (response) => {
          //console.log(response.status)
          return response.json() })
          /*
         .then(
              (data) => {
             console.log(data.message)
             
              })
              .catch(
                  (error) => {
                    console.log("Fetch hiba")
                    console.log(error)
                  
              })*/

        
}


/*async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  
  postData('https://example.com/answer', { answer: 42 })
    .then(data => {
      console.log(data); // JSON data parsed by `response.json()` call
    });*/