const INITIAL_STATE = {
    id : '',
    name : '',
    ip : ''
}


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'user_rpi':
      return {...state, id: action.id, name : action.name, ip : action.ip }
    default:
      return state;
  }
};
