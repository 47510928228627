export const gateControl_st = (garage, gate) => {  //zone 1 
    return {
      type: 'gatecontrol',
      garage : garage,
      gate : gate
      
    };
  };

  export const garageMessage_st = (data) => {  //zone 1 
    return {
      type: 'garagemessage',
      payload : data,
    };
  };

  export const gateMessage_st = (data) => {  //zone 1 
    return {
      type: 'gatemessage',
      payload : data,
    };
  };